import { Component, OnInit } from '@angular/core';
import { AfterViewInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Location } from '@angular/common'


import { NumberValidators } from '../shared/number.validator';
import { GenericValidator } from '../shared/generic-validator';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { buyerenquiries } from '../_models/buyerenquiries';
import { resolve } from 'dns';
import { format } from 'path';
import { Alert } from 'selenium-webdriver';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-addeditenquiry',
  templateUrl: './addeditenquiry.component.html',
  styleUrls: ['./addeditenquiry.component.scss']
})
export class AddeditenquiryComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  
  deciding_time1;

  pageTitle: string = 'Update Customer';
    errorMessage: string;
    buyerenqForm: FormGroup;
    buyerenq: buyerenquiries = <buyerenquiries>{};
    private sub: Subscription;
    showImage: boolean;
    imageWidth: number = 100;
    imageMargin: number = 2;
    fieldColspan = 3;
    carmakerList = [];
    carvarient1 = [];
    carvarient2 = [];
    agentList = [];
    executives = [];
    selectedCar1Option: string ="";
    enqID: number=0;
    isDisabled: boolean = false;
    isNewEntry: boolean = true;

    displayMessage: { [key: string]: string } = {};
    private genericValidator: GenericValidator;

    private validationMessages: { [key: string]: { [key: string]: string } | {} } = {
      name: {
          required: 'Customer name is required.'
      },
      username: {
          required: 'Executive username is required.'
      },
      password: {
          required: 'Executive password is required.'
      },      
      phone: { maxlength: 'Custome phone is required.' },
      comments:{required: 'Comments are reuired.'}
    };

  constructor(private fb : FormBuilder,private breakpointObserver: BreakpointObserver, private datePipe: DatePipe,
    private authenticationService: AuthenticationService, private route:ActivatedRoute, private location: Location) { 

      breakpointObserver.observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait
          ]).subscribe(result => {
              // console.log(result)
              this.onScreensizeChange(result);
          });
          this.genericValidator = new GenericValidator(this.validationMessages);
    }

  ngOnInit(): void {

     this.route.params.subscribe(params => {
      //alert(params.enquiryID)
        if(params.enquiryID != undefined){
          //alert("asdsad")
          this.enqID = params.enquiryID
          this.isNewEntry = false;
        }
        else{
          this.isNewEntry = true;
        }
    })

    this.buyerenqForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      phone: ['',[Validators.required, Validators.maxLength(12)]],
      comments: ['',[Validators.required, Validators.maxLength(200)]],
      car1: [''],
      variant1: [''],
      car2: [''],
      variant2: [''],
      year: [''],
      car3: [''],
      colornp: [''],
      numbernp: [''],
      //decidingTime: [''],
      assignedAgentId: [''],
      enq_status:[''],
      source: [''],
      //deciding_time: [''],
      agent_assigned_name: ['']
      
  });

  console.log(this.buyerenqForm);

    //this.selectedCar1Option = "Audi";
    // this.buyerenqForm.patchValue({
    //   name: "this.customer.firstname"

    // })
    //alert(this.selectedCar1Option);
    this.loadCarMaker();
    this.LoadExecutiveList();
    this.loadEnqData();
    
    
  }
  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
        .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.buyerenqForm.valueChanges, ...controlBlurs).debounceTime(500).subscribe(value => {
        this.displayMessage = this.genericValidator.processMessages(this.buyerenqForm);
    });
}

  loadEnqData(){

        this.authenticationService.getEnqDetails("","",this.enqID)
        .then(data => {
          var formData = data["posts"]

          //alert(JSON.stringify(formData));

          this.buyerenq = formData[0];
          this.buyerenq.enquiryID = formData[0].enq_id;
          this.buyerenq.name = formData[0].buyer_name;
          this.buyerenq.phone = formData[0].buyer_phone;
          this.buyerenq.car1 = formData[0].car1;
          this.carmakechange1(this.buyerenq.car1);
          this.buyerenq.variant1 = formData[0].variant1;
          this.buyerenq.car2 = formData[0].car2;
          this.carmakechange2(this.buyerenq.car2);
          this.buyerenq.variant2 = formData[0].variant2;
          this.buyerenq.year = formData[0].year;
          this.buyerenq.car3 = formData[0].car3;
          this.buyerenq.colourNotPrefered = formData[0].colourNotPrefered;
          this.buyerenq.numberNotPrefered = formData[0].numberNotPrefered;
          this.buyerenq.agent_assigned_name = formData[0].agent_assigned_name;
          var a = new Date(formData[0].deciding_time);
           if(a.toString() !== "Invalid Date"){
            this.deciding_time1 = new FormControl(new Date(formData[0].deciding_time).toISOString());
          }
          this.buyerenq.source = formData[0].source;
          this.buyerenq.enq_status = formData[0].enq_status;
          

          if(formData[0].enq_status == "On Going")
          {
            this.buyerenq.enq_status = "Inprogress"
          }

  
          


          //alert(this.buyerenq.deciding_time);
          //return;
          if(this.buyerenq.deciding_time == undefined){
            this.buyerenq.deciding_time = "ASAP"
          }
            this.buyerenqForm.patchValue({
              
              name: this.buyerenq.name,
              phone: this.buyerenq.phone,
              car1: this.buyerenq.car1,
              car2: this.buyerenq.car2,
              variant1: this.buyerenq.variant1,
              variant2: this.buyerenq.variant2,
              year: this.buyerenq.year,
              car3: this.buyerenq.car3,
              colornp: this.buyerenq.colourNotPrefered,
              numbernp: this.buyerenq.numberNotPrefered,
              deciding_time1: this.buyerenq.deciding_time,
              agent_assigned_name: this.buyerenq.agent_assigned_name,
              enq_status: this.buyerenq.enq_status
            });
        });

        
  }
  getTomorrow() {
    //alert("date called");
    let d = new Date();
    d.setDate(d.getDate() + 1);
    return this.datePipe.transform(d, 'yyyy-MM-dd');
  }

  LoadExecutiveList(){

    this.authenticationService.getAllExecutive().then(data=>{
      //debugger;
      this.executives = data["posts"];
      //alert(JSON.stringify(this.executives));
      
      });
  }
  goback(){
    this.location.back();
  }

  saveBuyerEnq(){
    //alert(this.enqID)
    if(this.enqID != 0){
      if (this.buyerenqForm.dirty && this.buyerenqForm.valid) {
        //alert(this.deciding_time1);
        const customer1 = Object.assign({}, this.buyerenq, this.buyerenqForm.value);
        //alert(JSON.stringify(customer1));
        //return;
        this.authenticationService.editBuyerEnq(customer1)
        .then(data => {
          let a = data["posts"]
          if(a.status == "success")
          {
            alert("Edited successfully!")
            //window.location.reload();
          }

          //alert(JSON.stringify(data));

        });
      }
      return;
    }
    if (this.buyerenqForm.dirty && this.buyerenqForm.valid) {
       // Copy the form values over the customer object values
      const customer = Object.assign({}, this.buyerenq, this.buyerenqForm.value);
      //alert(customer)
      // if(customer.decidingTime == "DATE")
      // {
      //   customer.decidingTime = customer.datepicker
      // }
      // else
      // {
      //   customer.decidingTime = "ASAP"
      // }
      //customer.deciding_time = "ASAP"

      if(customer.deciding_time == "")
      {
        customer.deciding_time = "ASAP"
      }
      //JSON.stringify(this.buyerenqForm.value);

      //alert(JSON.stringify(customer))

      //return;
      
     this.authenticationService.saveBuyerEnq(customer)
     .then(data => {
      let a = data["posts"]
      //alert(JSON.stringify(a))
      if(a.status == "success")
      {
        alert("Added successfully!")
        //window.location.reload();
      }

      //alert(JSON.stringify(data));

     });

      // this.customerService.saveCustomer(customer)
      //     .subscribe(
      //         () => this.onSaveComplete(),
      //         (error: any) => this.errorMessage = <any>error
      //     );
    } 
    // else if (!this.customerForm.dirty) {
    //   this.onSaveComplete();
    // }
    
  }

  loadCarMaker()
  {
    this.authenticationService.getCarMakerList("","")
    .then(data => {
      //alert(JSON.stringify(data));
      this.carmakerList = data["posts"];
    })

  }

  changeDT(value:any)
  {
    //alert(value);
    if(value=='DATE'){
      this.isDisabled = false;
    }
    
  }

  carmakechange1(value:any){
    //alert(value);
    // console.log($event.target.value)
    // let userid = window.localStorage.getItem("userid")
    // let password = window.localStorage.getItem("password")
    this.authenticationService.getCarVarientList("userid","password,$event",value)
    .then(data=>{
      this.carvarient1= data["posts"];
      //alert(JSON.stringify(this.carvarient));
    });

  }

  carmakechange2(value:any){
    //alert(value);
    // console.log($event.target.value)
    // let userid = window.localStorage.getItem("userid")
    // let password = window.localStorage.getItem("password")
    this.authenticationService.getCarVarientList("userid","password,$event",value)
    .then(data=>{
      this.carvarient2= data["posts"];
      //alert(JSON.stringify(this.carvarient));
    });

  }

  loadCarVarient(makeid:number){

  }

  onScreensizeChange(result: any) {
    // debugger
    const isLess600 = this.breakpointObserver.isMatched('(max-width: 599px)');
    const isLess1000 = this.breakpointObserver.isMatched('(max-width: 959px)');
    console.log(
        ` isLess600  ${isLess600} 
        isLess1000 ${isLess1000}  `
    )
    if (isLess1000) {
        if (isLess600) {
            this.fieldColspan = 12;
        }
        else {
            this.fieldColspan = 6;
        }
    }
    else {
        this.fieldColspan = 3;
    }
  }

}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../_services";


@Component({
  selector: 'app-login-review',
  templateUrl: './login-review.component.html',
  styleUrls: ['./login-review.component.scss']
})
export class LoginReviewComponent implements OnInit {
  @Output() isAuth = new EventEmitter<boolean>();
  model: any = {};
  isValidating = false;
  returnUrl: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.model.username;
    this.model.password;
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "loading";
  }

  login() {
    alert("sssss")
    this.isValidating = true;
    // this.isloading = true;
    this.authenticationService.reviewerLogin(this.model).subscribe(
      () => {
        // this.isAuthenticated =  true;
        console.log(" next action here ... " );
      },
      error => {
        console.log(error);
        this.isValidating = false;
      },
      ()=>{
        this.isValidating = false;
        console.log("login " + this.returnUrl);
        this.isAuth.emit(true);
        this.router.navigate([this.returnUrl]);
      }
    );
  }

}

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient,  } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { User } from '../_models';
import db from "./demo.db";
import { observable } from 'rxjs';
import { promise } from 'protractor';
import { Executive } from '../_models/executive';
import { buyerenquiries } from '../_models/buyerenquiries';
// import { AuthenticationService } from '.';


@Injectable()
export class BackendService {

  // private baseUrl: string = "https://internalconsole.com/V1_Dev/testdata/getdetails.php";
  // private execList: string ="https://internalconsole.com/V1_Dev/testdata/getdetails.php?function=getagentslist&company_id=1&username=super_admin&pwd=admin@123"

  private baseUrl: string = "https://ninethgearcrm.com//V1_Dev/testdata/getdetails.php";
  private execList: string ="https://ninethgearcrm.com//V1_Dev/testdata/getdetails.php?function=getagentslist&company_id=1&username=super_admin&pwd=admin@123"
 
  ds: any;

  constructor(private http: HttpClient, 
    private location: Location) {
    // console.log(http);
    // this.location.prepareExternalUrl(this.baseUrl);
    this.ds = Object.assign({}, db) || {}
    console.log(this.ds)
  }

  getModel(action) {
    if (action.includes('?') && action.includes('/')) {
      return action.indexOf('?') > action.indexOf('/') ? action.substring(0, action.indexOf('/')) : action.substring(0, action.indexOf('?'))
    } else {
      return action.includes('?') ? action.substring(0, action.indexOf('?')) : action.substring(0, action.indexOf('/'))
    }
  }

  getId(action, model) {
    action = action.substr(model.length + 1)
    return action.length > 0 && (action.includes('?') ? action.substring(0, action.indexOf('?')) : action)
  }

  getExpand(action, model) {
    action = action.substr(action.indexOf('?'))
    return action.includes('_expand') ? (
      action.includes('&') ?
        action.substring('_expand='.length + 1, action.indexOf('&')) :
        action.substring('_expand='.length + 1)) : undefined
  }

  getEmbed(action) {
    return action.includes('?') ? action.substring(action.indexOf('/'), action.indexOf('?')) : action.substring(action.indexOf('/'))
  }

  getData(action: string) {
    const self = this
    return new Promise(function (resolve, reject) {
      const model = self.getModel(action)
      const idStr = self.getId(action, model)
      const id = isNaN(idStr) ? undefined : parseInt(idStr)
      let exp = self.getExpand(action, model)
      const expandModel = exp ? exp === "category" ? "categories" : exp + "s" : exp
      const embed = self.getEmbed(action)
      console.log(model)
      let result
      let expand, expandId
      console.log(expandModel)
      if (model in self.ds) {
        if (id) {
          result = self.ds[model][self.ds[model].findIndex(d => d.id === id)]

          if (expandModel) {
            expand = expandModel === "categories" ? "category" : expandModel.substr(0, expandModel.length - 1)
            expandId = result[expand + "Id"]
            result[expand] = self.ds[expandModel][self.ds[expandModel].findIndex(d => d.id === expandId)]
          }
        } else {
          result = self.ds[model].map(m => {
            if (expandModel) {
              expand = expandModel === "categories" ? "category" : expandModel.substr(0, expandModel.length - 1)
              expandId = m[expand + "Id"]
              m[expand] = self.ds[expandModel][self.ds[expandModel].findIndex(d => d.id === expandId)]
            }
            return m
          })
        }
      }
      setTimeout(resolve, 200, { data: result })
    });
  }


  getAll(action: string) {
    return Observable.fromPromise(this.getData(action))
  }

  getByQuery(action: string) {
    return Observable.fromPromise(this.getData(action))
  }

  getById(action: string) {
    // const url = `${this.baseUrl}${action}`
    // return this.http.get(url, this.jwt()).map((response: Response) => response.json());
    return Observable.fromPromise(this.getData(action))
  }

  create(action: string, data: any) {
    return Observable.fromPromise(new Promise(function (resolve, reject) {
      const model = this.getModel(action)
      data.id = this.ds[model] + 1
      this.ds[model].push(data)
      setTimeout(resolve, 200, { data: data })
    }))
  }

  update(action: string, data: any) {
    return Observable.fromPromise(new Promise(function (resolve, reject) {
      const model = this.getModel(action)
      const idx = this.ds[model].findIndex(d => d.id === data.id)
      this.ds[model][idx] = Object.assign({}, data)
      setTimeout(resolve, 200, { data: data })
    }))

  }

  delete(action: string) {
    return Observable.fromPromise(new Promise(function (resolve, reject) {
      const model = this.getModel(action)
      const id = this.getId()
      id && this.ds[model].splice(this.ds[model].findIndex(d => d.id === id))
      setTimeout(resolve, 200, { status: 200 })
    }))
  }

  login(action: string, user: User) {
    const self = this;
    console.log(this.ds)
    return Observable.fromPromise(new Promise(resolve => {
      var url = "";
      if(user.username == "super_admin")
      {
        url = this.baseUrl+'?function=web_login1&username='+user.username+'&password='+user.password;
      }
      else{
        url = this.baseUrl+'?function=web_login_reviewer&username='+user.username+'&password='+user.password+'&company_id=1';
      }
      this.http.get(url).subscribe(data => {
        //alert("dd")
        //alert(JSON.stringify(data));
        resolve(data);
        
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    }));
  }

  reviewerlogin(action: string, user: User) {
    const self = this;
    console.log(this.ds)
    return Observable.fromPromise(new Promise(resolve => {
      this.http.get(this.baseUrl+'?function=web_login_reviewer&username='+user.username+'&password='+user.password+'&company_id=1').subscribe(data => {
        //alert("dd")
        resolve(data);
        
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    }));
  }

  getEnqDetails(username:any,password:any,enqId:number)
  {
    
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=buyer_request_detail&request_id=6564
    //&admin_username=super_admin&pwd=admin@123&company_id=1
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    
     return new Promise(resolve => {
      this.http.get(this.baseUrl+'?function=buyer_request_detail&request_id='+ enqId+'&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber='+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }
  multiAssign(enqIds:string,comment:string,execuname:string)
  {
    return new Promise(resolve => {
      this.http.get(this.baseUrl+'?function=multi_agent_assignemnt&enq_ids='+enqIds+'&comment='+ comment +'&agent_assigned='+
        execuname+'&admin_username=super_admin&user_type=2&pwd=admin@123&company_id=1').subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getAllExecs(){
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    //alert("service")
    // return Observable.fromPromise(new Promise(resolve =>{
    //   this.http.get(this.execList).subscribe(data => {
    //     resolve(data);
    //     //alert(JSON.stringify(data))
    //   },err => {
    //     alert(JSON.stringify(err))
    //   });
    // }));

     return new Promise(resolve => {
      this.http.get(this.execList+'&randomNumber='+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });
  }
  
  updateEnqComment(enqID:any,comments:any,userId:any,password:any)
  {
    //var notes = encodeURIComponent(this.co)
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=
    //buyer_enq_comments_update&comment=comments by super admin test again&enq_id=6564&admin_user=super_admin&pwd=admin@123&company_id=1
    comments = comments.replace(/'/g, "\\'")
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=buyer_enq_comments_update&comment="+comments+"&enq_id="+ enqID + "&admin_user=super_admin&pwd=admin@123&company_id=1").subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  updateReviewStatus(enqID:any,comments:any,userId:any,password:any,status:any,userType:any)
  {
    //var notes = encodeURIComponent(this.co)
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=
    //buyer_enq_comments_update&comment=comments by super admin test again&enq_id=6564&admin_user=super_admin&pwd=admin@123&company_id=1
    //https://ninethgearcrm.com/V1_Dev/testdata/getdetails.php?function=buyer_enq_update_review&comment=review comment&reviewstatus=1&enq_id=6873&username=shafi&pwd=shafi@123&company_id=1
    comments = comments.replace(/'/g, "\\'")
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=buyer_enq_update_review&comment="+comments+"&reviewstatus="+ status+"&enq_id="+ enqID + "&username="+ userId +"&pwd="+password+"&user_type="+ userType +"&company_id=1").subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  deleteEnqComment(commentId:any,userId:any,password:any)
  {
    //var notes = encodeURIComponent(this.co)
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=
    //buyer_enq_comments_update&comment=comments by super admin test again&enq_id=6564&admin_user=super_admin&pwd=admin@123&company_id=1
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=deletecomments&comment_id=8686&agent_id=14&pwd=test@123
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=deletecomments&comment_id="+commentId+"&admin_user=super_admin&pwd=admin@123&company_id=1").subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }
  deleteEnq(enqId:any,userId:any,password:any)
  {
    //var notes = encodeURIComponent(this.co)
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=
    //buyer_enq_comments_update&comment=comments by super admin test again&enq_id=6564&admin_user=super_admin&pwd=admin@123&company_id=1
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=deletecomments&comment_id=8686&agent_id=14&pwd=test@123


    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=delete_enquiry&enquiry_id="+enqId+"&admin_user=super_admin&pwd=admin@123&company_id=1").subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  saveExec(exec:Executive){
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    //alert("service")
    // return Observable.fromPromise(new Promise(resolve =>{
    //   this.http.get(this.execList).subscribe(data => {
    //     resolve(data);
    //     //alert(JSON.stringify(data))
    //   },err => {
    //     alert(JSON.stringify(err))
    //   });
    // }));


    //var uri = "http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=addagent&agent_name=test-web&agent_loc=
    //blr&agent_phone=7890345688&agent_username=test-web&agent_password=test@123&agent_company_id= 1&admin_username=super_admin&pwd=admin@123"

    var values = "&agent_name="+exec.name+"&agent_loc="+exec.location+"&agent_phone="+exec.phone+"&agent_password="
    +exec.password+"&agent_username="+exec.username+"&agent_company_id=1&admin_username=super_admin&pwd=admin@123"

     return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=addagent"+values).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });
  }
  getbuyerenquiriesall(){
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    console.log(this.baseUrl+"?function=get_buyer_enquiries&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber);

    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_buyer_enquiries&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });
  }

  getbuyerenquiriessubmitted(){
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    //let enq = [{"name":"Buyer 1","phone":"9988776655","Enquiries":{"id":"1","createdon":"11/03/2022","updatedon":"11/03/2022","car1":"BMW","varient":"520D","cretedby":"Tyeb",
    //"status":"important","assignedto":"Tyeb"}}]

    // let enq = [{"buyer":{"id":"1","name":"Buyer 1","phone":"9988776655","enquiries":{"status":"Assigned","car":"BMW 5 Series","Source":"car wale","Lastupdatedon":"12/03/2022","lastupdatedby":"Tayeb"}}}]

    // return enq;
    //alert("bbbb")
    console.log(this.baseUrl+"?function=get_buyer_enquiries_submitted&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber);

    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_buyer_enquiries_submitted&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });
  }
  getEnqbyExe(){
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    //let enq = [{"name":"Buyer 1","phone":"9988776655","Enquiries":{"id":"1","createdon":"11/03/2022","updatedon":"11/03/2022","car1":"BMW","varient":"520D","cretedby":"Tyeb",
    //"status":"important","assignedto":"Tyeb"}}]

    // let enq = [{"buyer":{"id":"1","name":"Buyer 1","phone":"9988776655","enquiries":{"status":"Assigned","car":"BMW 5 Series","Source":"car wale","Lastupdatedon":"12/03/2022","lastupdatedby":"Tayeb"}}}]

    // return enq;
    console.log(this.baseUrl+"?function=get_enqByAgent_count&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber);

    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_enqByAgent_count&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }
  getNotFollowedUp(){
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    console.log(this.baseUrl+"?function=get_not_followed_up&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber);

    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_not_followed_up&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getEnqListbyStatus(status:string){
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    //let enq = [{"name":"Buyer 1","phone":"9988776655","Enquiries":{"id":"1","createdon":"11/03/2022","updatedon":"11/03/2022","car1":"BMW","varient":"520D","cretedby":"Tyeb",
    //"status":"important","assignedto":"Tyeb"}}]

    // let enq = [{"buyer":{"id":"1","name":"Buyer 1","phone":"9988776655","enquiries":{"status":"Assigned","car":"BMW 5 Series","Source":"car wale","Lastupdatedon":"12/03/2022","lastupdatedby":"Tayeb"}}}]

    // return enq;
    console.log(this.baseUrl+"?function=get_buyer_enquiries_bystatus&enq_status="+status+"&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber);

    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_buyer_enquiries_bystatus&enq_status="+status+"&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getEnqResponses(enqID:string){
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=getenqcomments&user_type=1&agent_id=14&admin_id=super_admin&pwd=admin@123&request_id=6564
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    
    console.log(this.baseUrl+"?function=getenqcomments&user_type=1&admin_id=super_admin&pwd=admin@123&request_id="+enqID+"&randomNumber"+randomNumber)

    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=getenqcomments&user_type=1&admin_id=super_admin&pwd=admin@123&request_id="+enqID+"&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }
 
  saveBuyerEnq(buyerEnq:buyerenquiries)
  {
    //alert("Reached here");
    buyerEnq.comments = encodeURIComponent(buyerEnq.comments);

    //alert(JSON.stringify(buyerEnq));

    var date = new Date();
    var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();


    //return;

    var queryString = "name="+buyerEnq.name+"&phone="+buyerEnq.phone+"+&car1="+buyerEnq.car1+"&variant1="+buyerEnq.variant1
    +"&year1="+buyerEnq.year+"&car2="+buyerEnq.car2+"&variant2="+buyerEnq.variant2+"&car3="+buyerEnq.car3
    +"&deciding_time="+buyerEnq.deciding_time+"&source="+buyerEnq.source+"&car_available=1&comment="+buyerEnq.comments
    +"&agent_id=18&pwd=admin@123&added_time="+str+"&company_id=1&user_type=1&admin_user=super_admin&enq_status=On Going&agent_assigned=newexe&user_type=1"
    queryString = queryString.replace(/'/g, "\\'")

    // name=test&phone=9633759989&car1=BMW&variant1=5 series&year1=2016&&car2=Audi&variant2=Q5&car3=porsche&
    // deciding_time=ASAP&source=internet&car_available=1&comment=should be in showroom condition&customer_type=Ordinary&enq_status=important&agent_assigned=test4&user_type=1&admin_user=super_admin&pwd=admin@123&added_time=2022-06-02 03:58:20&company_id=1

    alert(queryString)
    //return;
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=buyer_request&"+queryString).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }
  editBuyerEnq(buyerEnq:buyerenquiries)
  {
    //alert("Reached here");
    buyerEnq.comments = encodeURIComponent(buyerEnq.comments);


    var date = new Date();
    var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    var status;

    if(buyerEnq.enq_status == "Inprogress")
    {
      status = "On Going";
    }
    else{
      status = buyerEnq.enq_status;
    }

    var queryString = "name="+buyerEnq.name+"&phone="+buyerEnq.phone+"+&car1="+buyerEnq.car1+"&variant1="+buyerEnq.variant1
    +"&year1="+buyerEnq.year+"&car2="+buyerEnq.car2+"&variant2="+buyerEnq.variant2+"&car3="+buyerEnq.car3
    +"&deciding_time="+buyerEnq.deciding_time+"&source="+buyerEnq.source+"&car_available=1&comment="+buyerEnq.comments+"&enq_id="+buyerEnq.enquiryID
    +"&agent_id=18&pwd=admin@123&added_time="+str+"&company_id=1&user_type=1&admin_user=super_admin&enq_status="+status+"&agent_assigned="+buyerEnq.agent_assigned_name+"&user_type=1"
    queryString = queryString.replace(/'/g, "\\'")

    // name=test&phone=9633759989&car1=BMW&variant1=5 series&year1=2016&&car2=Audi&variant2=Q5&car3=porsche&
    // deciding_time=ASAP&source=internet&car_available=1&comment=should be in showroom condition&customer_type=Ordinary&enq_status=important&agent_assigned=test4&user_type=1&admin_user=super_admin&pwd=admin@123&added_time=2022-06-02 03:58:20&company_id=1

    //alert(queryString)
    //return;
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=edit_buyer_enquiry&"+queryString).subscribe(data => {
        debugger;
        //alert("asdsad")
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getCarMakerList(userid:any,password:any){
    userid = 8
    password = "tayeb@123"
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    return new Promise(resolve => {
      this.http.get(this.baseUrl+'?function=getmakeall&agent_id='+userid+'&pwd='+password+'&randomNumber='+randomNumber).subscribe(data => {
        //alert("dd")
        resolve(data);
        //alert(JSON.stringify(data));
        
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getCarVarientList(userid:any,password:any,carmake:string){
    userid = 8
    password = "tayeb@123"
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    //http://internalconsole.com/V1_Dev/testdata/getdetails.php?function=getvariantbyname&manufacturer_name=bmw&agent_id=8&pwd=tayeb@123
    return new Promise(resolve => {
      this.http.get(this.baseUrl+'?function=getvariantbyname&manufacturer_name='+carmake+'&agent_id='+userid+'&pwd='+password+'&randomNumber='+randomNumber).subscribe(data => {
        //alert("dd")
        resolve(data);
        //alert(JSON.stringify(data));
        
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });
  }

  getDashboardData()
  {
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_dashboard_count&admin_username=super_admin&pwd=admin@123&company_id=1&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getNewEnquiries()
  {
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_todays_walkin&admin_username=super_admin&pwd=admin@123&company_id=1@randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getInReviewEnquiries(username:string,password:string,usertype:string)
  {
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_pending_for_review&username="+username+"&pwd="+password+"&company_id=1&user_type="+ usertype +"&randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  getSellerEnquireis()
  {
    let randomNumber =  Math.floor(Math.random() * (10000 - 1 + 1)) + 1
    return new Promise(resolve => {
      this.http.get(this.baseUrl+"?function=get_sellers&admin_username=super_admin&pwd=admin@123&company_id=1@randomNumber"+randomNumber).subscribe(data => {
        debugger;
        resolve(data);
      }, err => {
        console.log(err);
        alert(JSON.stringify(err));
      });
    });

  }

  // private helper methods
  // private form() {
  //   let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
  //   return { headers: headers };
  // }

  // private jwt() {
  //   // create authorization header with jwt token
  //   let user =  this.authService.getUser() //JSON.parse( );
  //   if (user && user.token) {
  //     let headers = new Headers({ 'Authorization': 'Bearer ' + user.token });
  //     return { headers: headers } ;
  //   }
  // }

  // private handleError(error: Response) {
  //   console.error(error);
  //   return Observable.throw(error.json() || 'Server error');
  // }
}

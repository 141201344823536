import { Injectable } from '@angular/core';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { User } from '../_models'
import { BackendService } from './backend.service'
import { Executive } from '../_models/executive';
import { buyerenquiries } from '../_models/buyerenquiries';


const APP_USER_PROFILE = "NG_CRM_USER_2.0"
@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private backend: BackendService) { }

  login(user: any) {
    //alert(user.password);
    let b = user;
    return this.backend.login('token', user)
      .map((response: Response) => {
         // login successful if there's a token in the response
        let data = (<any>response);   
        //alert(JSON.stringify(data))     
        if(data.posts[0].status=="success")
        {
          let k = b.password
          let a = {
            userid : data.posts[0].userid,
            isAuthenticated : true,
            name : data.posts[0].name,
            usertype: data.posts[0].usertype
          }
          let user = a;
          localStorage.setItem(APP_USER_PROFILE, JSON.stringify(user));
          localStorage.setItem("username", data.posts[0].username);
          localStorage.setItem("password", k)
          localStorage.setItem("usertype",data.posts[0].usertype)
        }
        else{
          alert("login failed")
        }
      });
  }

  reviewerLogin(user: any) {
    alert('aaa');
    return this.backend.login('token', user)
      .map((response: Response) => {
         // login successful if there's a token in the response
        let data = (<any>response);   
        //alert(JSON.stringify(data))     
        if(data.posts[0].status=="success")
        {
          let a = {
            userid : data.posts[0].userid,
            isAuthenticated : true,
            name : data.posts[0].name
          }
          let user = a;
          localStorage.setItem(APP_USER_PROFILE, JSON.stringify(user));
          localStorage.setItem("username", data.posts[0].username);
          //alert(localStorage.getItem("username"));
        }
        else{
          alert("login failed")
        }
      });
  }

  getAllExecutive()
  {

    return this.backend.getAllExecs()
    
  }

  multiAssign(enqIds:string,comment:string,agentName:string)
  {
    return this.backend.multiAssign(enqIds,comment,agentName)

  }

  getEnqDetails(username:any,password:any,enqId:number)
  {

    return this.backend.getEnqDetails(username,password,enqId)
    
  }

  updateEnqComment(enqID:any,comments:any,userId:any,password:any)
  {
    return this.backend.updateEnqComment(enqID,comments,userId,password)
  }

  updateReviewStatus(enqID:any,comments:any,userId:any,password:any,status:any,userType:any)
  {
    return this.backend.updateReviewStatus(enqID,comments,userId,password,status,userType)
  }

  deleteEnqComment(commentId:any,userId:any,password:any)
  {
    return this.backend.deleteEnqComment(commentId,userId,password)
  }

  deleteEnq(enqId:any,userId:any,password:any)
  {
    return this.backend.deleteEnq(enqId,userId,password)
  }

  getDashboardData()
  {
    return this.backend.getDashboardData()
  }

  getCarMakerList(userid:any,password:any)
  {
    return this.backend.getCarMakerList(userid,password);
  }

  getCarVarientList(userid:any,password:any,carmake:string)
  {
    return this.backend.getCarVarientList(userid,password,carmake);
  }

  saveExecutive(exec:Executive){
    return this.backend.saveExec(exec);

  }

  getNewEnquiries(){
    return this.backend.getNewEnquiries();
  }

  getSellerEnquireis(){
    return this.backend.getSellerEnquireis();

  }

  getbuyerenquiriessubmitted(){
    //alert("aaas")
    return this.backend.getbuyerenquiriessubmitted();
  }
  getbuyerenquiriesall(){
    return this.backend.getbuyerenquiriesall();
  }

  getinreview(username:string,password:string,usertype:string){
    return this.backend.getInReviewEnquiries(username,password,usertype)
  }

  getEnqListbystatus(status:string){
    return this.backend.getEnqListbyStatus(status);
  }

  getNotFollowedUp(){
    return this.backend.getNotFollowedUp();
  }

  getEnqbyExe(){
    return this.backend.getEnqbyExe();
  }

  getEnqResponses(enqId:string){
    return this.backend.getEnqResponses(enqId);
  }
  saveBuyerEnq(buyEnq:buyerenquiries)
  {
    return this.backend.saveBuyerEnq(buyEnq)
  }
  editBuyerEnq(buyEnq:buyerenquiries)
  {
    return this.backend.editBuyerEnq(buyEnq)
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(APP_USER_PROFILE);
  }

  isAuthenticated() {
    let user =   this.getUser() // <User>JSON.parse(localStorage.getItem(APP_USER_PROFILE));
    return user && user.isAuthenticated ? true : false;
  }

  getUser(){
    let user = <User>JSON.parse(localStorage.getItem(APP_USER_PROFILE));
    return user;
  }

}



<mat-progress-bar *ngIf="isloading"  class="progress-bar"   mode="indeterminate"  ></mat-progress-bar>

<div *ngIf="!authService.isAuthenticated()">
  <login-form (isAuth)="isAuth($event)"></login-form>
</div>
<!-- <mat-progress-bar mode="indeterminate" class=""></mat-progress-bar> -->
<div *ngIf="authService.isAuthenticated()" [ngClass]="uiContent"  role="main">
  <!-- calss="content" -->
  <mat-sidenav-container class="app-container" autosize fullscreen>
   <mat-sidenav dark #sidenav id="appnav" [mode]="mode" [opened]="!isMobile">
      <mat-card   class="profile-card">
        <mat-card-header  >  
          <div  mat-card-avatar class="avatar">
            <img src="/assets/img/logo.png" class="avatar">
          </div>

          <mat-card-title [hidden]="user!==null && user!==undefined" style="padding:5px;">
            {{user?.name}}
          </mat-card-title>

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
      
        </mat-card-header> 
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout()">Logout</button>
          <button mat-menu-item>Change Password</button>
        </mat-menu>
      </mat-card>
      <mat-nav-list >
        <a  mat-list-item routerLink="dashboard">
          <mat-icon mat-list-icon >insert_chart_outline</mat-icon>
          <span mat-line>Dashboard</span>
        </a>
        <!-- <a mat-list-item routerLink="orders">
          <mat-icon mat-list-icon>shopping_cart</mat-icon>
          <span mat-line>Order</span>
        </a> -->
        <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="executives">
          <mat-icon mat-list-icon>account_box</mat-icon>
          <span mat-line>Executives</span>
        </a>
        <!-- <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="executiveenqview">
          <mat-icon mat-list-icon>assessment</mat-icon>
          <span mat-line>executive work view</span>
        </a> -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-icon mat-list-icon>assignment</mat-icon>
            <span mat-line>&nbsp;&nbsp;&nbsp;Enquiries</span>
          </mat-expansion-panel-header>      
          <a mat-list-item routerLink="importantenqs">
            <mat-icon mat-list-icon>notification_important</mat-icon>
            <span mat-line>&nbsp;&nbsp;&nbsp;Important &nbsp;({{important}})</span>            
          </a>
          <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="newenquiries">
            <mat-icon mat-list-icon>directions_walk</mat-icon>
            <span mat-line>Today's walkin ({{newentry}})</span>
            
          </a>
          <a mat-list-item routerLink="inreview">
            <mat-icon mat-list-icon>rate_review</mat-icon>
            <span mat-line>In review ({{inreview}})</span>
            
          </a>
          <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="inprogress">
            <mat-icon mat-list-icon>assignment_ind</mat-icon>
            <span mat-line>&nbsp;&nbsp;&nbsp;Inprogres ({{inprogress}})</span>            
          </a>
          <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="submitted">
            <mat-icon mat-list-icon>assignment_turned_in</mat-icon>
            <span mat-line>&nbsp;&nbsp;&nbsp;Submitted ({{submitted}})</span>
            
          </a>
          <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="futurefollowups">
            <mat-icon mat-list-icon>event_note</mat-icon>
            <span mat-line> Future followups ({{futureFollowUp}})</span>            
          </a>
          <a *ngIf="isSupuerAdmin" mat-list-item routerLink="clientcallbac">
            <mat-icon mat-list-icon>phone_callback</mat-icon>
            <span mat-line> Client will get back ({{clientCallBack}})</span>           
          </a>
          <a mat-list-item routerLink="carnotavailable">
            <mat-icon mat-list-icon>directions_car</mat-icon>
            <span mat-line>Car not available ({{cna}})</span>
          </a>
          <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="bought">
            <mat-icon mat-list-icon>thumb_up_alt</mat-icon>
            <span mat-line>Bought ({{bought}})</span>
          </a>
          <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="lost">
            <mat-icon mat-list-icon>thumb_down_alt</mat-icon>
            <span mat-line>Lost ({{lost}})</span>
          </a>
          <!-- <a mat-list-item routerLink="lostforotherreasons">
            <mat-icon mat-list-icon>thumb_down_alt</mat-icon>
            <span mat-line>Lost for other reasons ({{lostforotherreasons}})</span>
          </a> -->
          <!-- <a mat-list-item routerLink="notfollowedup">
            <mat-icon mat-list-icon>warning</mat-icon>
            <span mat-line>Not Followedup ({{notFollowedUp}})</span>
          </a> -->
          <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="exportedtoreassign">
            <mat-icon mat-list-icon>warning</mat-icon>
            <span mat-line>Abort ({{reassign}})</span>
          </a>
        </mat-expansion-panel>

        <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="buyerenquiries">
          <mat-icon mat-list-icon>account_box</mat-icon>
          <span mat-line>Buyer enquiries</span>
        </a>
        <a  *ngIf="isSupuerAdmin" mat-list-item routerLink="sellerenquiries">
          <mat-icon mat-list-icon>store</mat-icon>
          <span mat-line>Seller enquiries</span>
        </a>
        <a  mat-list-item routerLink="addeditenquiry">
          <mat-icon mat-list-icon>group_add</mat-icon>
          <span mat-line>Add enquiry</span>
        </a>
        <!-- <a mat-list-item routerLink="about">
          <mat-icon mat-list-icon>info_outline</mat-icon>
          <span mat-line>About</span>
        </a> -->
      </mat-nav-list>



    </mat-sidenav>
    <!-- <mat-sidenav-content [hidden]="!authService.isAuthenticated()"> -->
    <mat-sidenav-content class="sidenav-content" > 
      <mat-toolbar dark style="background-color:rgb(228, 224, 224)">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon mat-list-icon>list</mat-icon>
        </button>

        <span style="margin-left: 20px;width:100%">
          
        </span>
        <span style="float:right">

        </span>
      </mat-toolbar>
      <div class="main-content" > <!-- [hidden]="!authService.isAuthenticated()"> -->
        <router-outlet></router-outlet>
      </div>
      <!-- <footer class="footer" [ngStyle]="{'position': isMobile?'relative':'absolute'}">
        <span style="font-family:sans-serif">&copy; 2020 NG CRM </span>
      </footer> -->
    </mat-sidenav-content>


  

  </mat-sidenav-container>

</div>





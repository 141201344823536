import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../_services';
import {sellererenquiries} from '../_models/sellerenquiries';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-sellerenquiries',
  templateUrl: './sellerenquiries.component.html',
  styleUrls: ['./sellerenquiries.component.scss']
})
export class SellerenquiriesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  sellerenquiries: any[];
  dataSource: any = null;
  displayedColumns = ["ID","name","phone","car","Source","DateCreated","last_updated_by_user","enquiryID"];
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.loadData();
  }
  
  loadData(){
    this.dataSource = this.authenticationService.getSellerEnquireis().then(data=>{
      
      //alert(JSON.stringify(data))
      this.dataSource = data["posts"][0].data;
      //alert(JSON.stringify(this.dataSource))
      this.freshDataList(this.dataSource);

      })
  }

  freshDataList(selenqs: any[]) {
    this.sellerenquiries = selenqs;

    this.dataSource = new MatTableDataSource(this.sellerenquiries);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // console.log(this.dataSource);
    // alert(JSON.stringify(this.dataSource));
  }

}

import { Component, OnInit } from '@angular/core';
import { Executive } from '../_models/executive';
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-executives',
  templateUrl: './executives.component.html',
  styleUrls: ['./executives.component.scss']
})
export class ExecutivesComponent implements OnInit {

  executives: Executive[];
  displayedColumns = ["name","location","phone","username","id"];
  dataSource: any = null;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    

    
    this.authenticationService.getAllExecutive().then(data=>{
      //debugger;
      this.executives = data["posts"];
      //console.log(this.executives[0])
      //console.log(this.executives);
      //alert(JSON.stringify(this.taskItems));
      this.freshDataList(this.executives);
      })
      
    }    
  

  freshDataList(execList: any[]) {
    //alert("Inside Fresh");
    this.executives = execList;
    //alert("Imran"+JSON.stringify(this.executives));

    this.dataSource = new MatTableDataSource(this.executives);
    console.log(this.dataSource);
    //alert("come on "+JSON.stringify(this.dataSource));

    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
}

}

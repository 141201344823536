import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import {ExecutiveEnq} from '../_models/ExecutiveEnq'

@Component({
  selector: 'app-executiveenqview',
  templateUrl: './executiveenqview.component.html',
  styleUrls: ['./executiveenqview.component.scss']
})
export class ExecutiveenqviewComponent implements OnInit {

  dataSource: any = null;
  execenq: ExecutiveEnq=<ExecutiveEnq>{};
  exeengdtls=[];
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    //alert("hi")
    this.dataSource = this.authenticationService.getEnqbyExe().then(data=>{
      
      this.dataSource = data["posts"];

      var i = 0;
      var j = 0;
      for (let key of this.dataSource) {
        
        // for(let objdata of key){
        //   //alert(JSON.stringify(objdata))
        //   //alert(objdata.name);
        //   if(objdata.name != undefined){
        //     this.execenq.Name = objdata.name;
        //   }
        //   if(objdata.data != undefined){
        //     alert(objdata.data)
        //   }
        //   //alert(this.execenq.Name);
        //   j++
        //}
         this.execenq.Name = key.name;
         var dataobj = key.data;
         var jsonObj = JSON.parse(dataobj);
         var a = {"name":key.name,"OnGoing":jsonObj["On Going"],"Submitted":jsonObj["Submitted"],
          "FutureFollowUp":jsonObj["Future Follow Up"],"Carnotavailable":jsonObj["Car not available"],
          "Clientwillgetback":jsonObj["Client will get back"]}
         
         //console.log(jsonObj);
         //alert(JSON.stringify(dataobj))
         //alert(jsonObj["On Going"])
         this.execenq.OnGoing = jsonObj["On Going"]
         console.log(this.execenq)
         this.exeengdtls.push(a)
         console.log(this.exeengdtls);
         //alert(JSON.stringify(this.exeengdtls));
         
        // alert(JSON.stringify(key[0]));
        i++
      }

          
      //alert(JSON.stringify(this.dataSource))
      
      //alert(this.dataSource[0])

      
      //alert(JSON.stringify(this.dataSource[0]["On Going"]))
      //this.freshDataList(this.dataSource);
9

      })
  }

}

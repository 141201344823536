import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AuthenticationService } from './_services';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
  username: string = "";
  title = 'ng crm';
  user: any = null;
  isMobile: boolean;
  mode = "side"
  uiContent = "content"
  progrssBarClass = "progress-bar";
  isloading = true;
  dataSource: any = null;
  submitted: number = 0;
  newentry: number = 0;
  important: number = 0;
  notFollowedUp: number =0;
  exported: number = 0;
  cna: number = 0;
  clientCallBack: number = 0;
  futureFollowUp: number = 0;
  bought: number =0;
  lost: number = 0;
  lostforotherreasons: number =0;
  inprogress: number = 0;
  reassign: number =0;
  inreview: number =0;
  isSupuerAdmin = false;
  

  constructor(
    // private loadingBar: SlimLoadingBarService,
    private router: Router,
    public authService: AuthenticationService,
    private breakpointObserver: BreakpointObserver
  ) {
    console.log(" constructor")

    this.isloading = true;

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      console.log(result)
      if (result.matches) {
        // this.activateHandsetLayout();
        this.isMobile = true;
        this.mode = "over"
        this.uiContent = "mobile-content"
      }
      else {
        this.isMobile = false;
        this.mode = "side"
        this.uiContent = "content"
      }
    });
    // breakpointObserver.ngOnDestroy()

    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    })
      ;
  }

  ngOnChanges() {
    console.log(" ngOnChanges")
  }


  ngOnInit(): void {
    this.username = localStorage.getItem("username");
    if(this.username == "super_admin")
    {
      this.isSupuerAdmin = true;
    }
    else{
      this.isSupuerAdmin = false;
    }
    
    //alert("imran"+this.username);
    console.log(" ngOnInit")
    this.user = this.authService.getUser();
    this.isloading = false;

    this.loadData();
  }

  logout(): void {
    // localStorage.removeItem('currentUser');
    this.authService.logout()
    this.router.navigate(['login']);
  }

  loadData()
  {

    this.dataSource = this.authService.getDashboardData()
    .then(data => {
      this.dataSource = data["posts"];
      //alert(JSON.stringify(this.dataSource));
      //alert(this.dataSource.length);
      for (let i = 0; i < this.dataSource.length; i++) {
        if(this.dataSource[i]["status"]=="Exported")
        {
          this.reassign = this.dataSource[i]["count"];
        }
        if(this.dataSource[i]["status"] == "submitted") {
          this.submitted = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Important"){
          this.important = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Not Followed Up")
        {
          this.notFollowedUp = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Exported")
        {
          this.exported = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="In Progress")
        {
          this.inprogress = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="CNA")
        {
          this.cna = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="ClientCallBack")
        {
          this.clientCallBack = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Future Follow Up")
        {
          this.futureFollowUp = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Bought")
        {
          this.bought = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="TodaysWalkin")
        {
          this.newentry = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Lost")
        {
          this.lost = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Pending for Review")
        {
          this.inreview = this.dataSource[i]["count"];
        }
        // else if(this.dataSource[i]["status"]=="Lost for other reasons")
        // {
          
        //   this.lostforotherreasons = this.dataSource[i]["count"];
        // }
        
        //console.log("Imran"+this.dataSource[i]);
      }
      //this.submitted = this.dataSource. 
      console.log(this.dataSource);

    });
    
  }



  isAuth(isAuth?: any) {
    if (isAuth) {
      this.user = this.authService.getUser()
      // this.user = JSON.parse(localStorage.getItem(APP_USER_PROFILE)) || <User>{};
    }
  }

  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this. progrssBarClass = "progress-bar";
      this.isloading = true;
    }
    if (event instanceof NavigationEnd) {
      this. progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }
    if (event instanceof NavigationCancel) {
      this. progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }
    if (event instanceof NavigationError) {
      this. progrssBarClass = "progress-bar-hidden";
      this.isloading = false;
    }

  }


  ngOnDestroy() {
    this.breakpointObserver.ngOnDestroy()
    this.authService.logout()
    //   this.router.events
    // this.breakpoint.
  }

}

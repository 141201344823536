/**
 * Angular  decorators and services
 */
import {
  Component,
  OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { AuthenticationService } from '../_services';


interface InfoBox {
  bgClass: string;
  icon: string;
  title: string;
  subtitle: string;
  routing: string;
}

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'dashboard',
  // encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  username: string = "";
  colNum: number = 4;
  rowHeight = '120px';
  chartColNum = 2;
  chartRowHeight = '450px';
  cardClass = 'dash-card';

  mediaQueryList: any = null;
  mediaQueryMin: any = null;
  isMobile = false;
  chartColspan = 1;
  dataSource: any = null;
  submitted: number = 0;
  newentry: number = 0;
  important: number = 0;
  notFollowedUp: number =0;
  exported: number = 0;
  cna: number = 0;
  clientCallBack: number = 0;
  futureFollowUp: number = 0;
  bought: number =0;
  lost: number = 0;
  lostforotherreasons: number =0;
  inprogress: number = 0;
  reassign: number =0;
  inreview: number =0;
  isSupuerAdmin = false;


  constructor(
    private breakpointObserver: BreakpointObserver,private authenticationService: AuthenticationService ) {
    // this.mediaQueryList = mediaMatcher.matchMedia('(min-width: 640px)');
    // this.mediaQueryMin = mediaMatcher.matchMedia('(min-width: 960px)');


    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      this.onScreensizeChange()
      
    });
    this.loadData();
  }

  loadData()
  {
    this.username = localStorage.getItem("username");
    if(this.username == "super_admin")
    {
      this.isSupuerAdmin = true;
    }
    else{
      this.isSupuerAdmin = false;
    }
    //this.inreview = 0;
    this.dataSource = this.authenticationService.getDashboardData()
    .then(data => {
      this.dataSource = data["posts"];
      //alert(JSON.stringify(this.dataSource));
      //alert(this.dataSource.length);
      for (let i = 0; i < this.dataSource.length; i++) {
        if(this.dataSource[i]["status"]=="Exported")
        {
          this.reassign = this.dataSource[i]["count"];
        }
        if(this.dataSource[i]["status"] == "submitted") {
          this.submitted = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Important"){
          this.important = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Not Followed Up")
        {
          this.notFollowedUp = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Exported")
        {
          this.exported = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="In Progress")
        {
          this.inprogress = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="CNA")
        {
          this.cna = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="ClientCallBack")
        {
          this.clientCallBack = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Future Follow Up")
        {
          this.futureFollowUp = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Bought")
        {
          this.bought = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="TodaysWalkin")
        {
          this.newentry = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Lost")
        {
          this.lost = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Lost for other reasons")
        {
          this.lostforotherreasons = this.dataSource[i]["count"];
        }
        else if(this.dataSource[i]["status"]=="Pending for Review")
        {
          this.inreview = this.dataSource[i]["count"];
        }
        //console.log("Imran"+this.dataSource[i]);
      }
      //this.submitted = this.dataSource. 
      console.log(this.dataSource);

    });
    
  }
  

  infoBoxes: InfoBox[] = [
    {
      bgClass: "user-registration",
      icon: "notification_important",
      title: "Important",
      routing:"importantenqs",
      subtitle: this.important.toString(),
    },

    {
      bgClass: "new-order",
      icon: "directions_walk",
      title: "Today's walkin",
      routing: "newenquiries",
      subtitle: "0",
    },
    {
      bgClass: "new-order",
      icon: "directions_walk",
      title: "Today's walkin",
      routing: "inreview",
      subtitle: "0",
    },
    {
      bgClass: "bounce-rate",
      icon: "assignment_ind",
      title: "Inprogress",
      routing:"inprogress",
      subtitle: "0",
    },
    {
      bgClass: "membership",
      icon: "assignment_turned_in",
      title: "Submitted",
      routing:"submitted",
      subtitle: "0",
    },
    {
      bgClass: "membership",
      icon: "event_note",
      title: "Future followups",
      routing: "futurefollowups",
      subtitle: "0",
    },
    {
      bgClass: "membership",
      icon: "phone_callback",
      title: "Client will get back",
      routing:"clientcallbac",
      subtitle: "0",
    },
    {
      bgClass: "membership",
      icon: "directions_car",
      title: "Car not available",
      routing:"carnotavailable",
      subtitle: "0",
    },
    {
      bgClass: "membership",
      icon: "thumb_up_alt",
      title: "Bought",
      routing:"bought",
      subtitle: "0",
    },
    {
      bgClass: "membership",
      icon: "thumb_down_alt",
      title: "Lost",
      routing:"lost",
      subtitle: "0",
    },
    {
      bgClass: "membership",
      icon: "thumb_down_alt",
      title: "Lost for other reasons",
      routing: "lostforotherreasons",
      subtitle: "0",
    }
  ]


  lineChartData: Array<number[]> = [
    [65, 59, 80, 81, 56, 55, 40],
    [28, 48, 40, 19, 86, 27, 90]
  ];
  lineChartLabels: Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  lineChartType: string = 'line';
  pieChartType: string = 'pie';

  lineChartOptions: any = {};

  // Pie
  pieChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  pieChartData: number[] = [300, 500, 100];

  randomizeType(): void {
    this.lineChartType = this.lineChartType === 'line' ? 'bar' : 'line';
    this.pieChartType = this.pieChartType === 'doughnut' ? 'pie' : 'doughnut';
  }

  chartClicked(e: any): void {
    console.log(e);
  }

  chartHovered(e: any): void {
    console.log(e);
  }

  ngOnInit() {

  }


  onScreensizeChange() {
    // debugger
    const isLess600 = this.breakpointObserver.isMatched('(max-width: 599px)');
    const isLess1000 = this.breakpointObserver.isMatched('(max-width: 959px)');
    console.log(
      ` isLess600  ${isLess600} 
        isLess1000 ${isLess1000}  `
    )
    if (isLess1000) {
      if (isLess600) {
        // this.fieldColspan = 12;
        this.colNum = 1;
        this.chartColNum = 1;
        this.chartColspan = 1;
      }
      else {
        this.colNum = 2;
        this.chartColNum = 1;
        this.chartColspan = 2;
      }
    }
    else {
      this.colNum = 4;
      this.chartColNum = 2;
      this.chartColspan = 2;
      
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard';
import { AboutComponent } from './about';
import { AuthGuard } from './_guard';
import { NotFoundPageComponent } from './notfoundpage';
import { LoginComponent } from './login';
import { LoadingComponent } from './loading';
import { ExecutivesComponent } from './executives/executives.component';
import { EnquiriesComponent } from './enquiries/enquiries.component';
import { BuyerenquiriesComponent } from './buyerenquiries/buyerenquiries.component';
import { SellerenquiriesComponent } from './sellerenquiries/sellerenquiries.component';
import {ImportantenqsComponent} from './importantenqs/importantenqs.component';
import {NewenquiriesComponent} from './newenquiries/newenquiries.component';
import { InprogressComponent } from './inprogress/inprogress.component';
import { SubmittedComponent } from './submitted/submitted.component';
import { FuturefollowupsComponent } from './futurefollowups/futurefollowups.component';
import { ClientcallbackComponent } from './clientcallback/clientcallback.component';
import { CarnotavailableComponent } from './carnotavailable/carnotavailable.component';
import { BoughtComponent } from './bought/bought.component';
import { LostComponent } from './lost/lost.component';
import { LostforotherreasonsComponent } from './lostforotherreasons/lostforotherreasons.component';
import { AddeditexecutiveComponent } from './addeditexecutive/addeditexecutive.component';
import { AddeditenquiryComponent } from './addeditenquiry/addeditenquiry.component';
import { EnqresponsesComponent } from './enqresponses/enqresponses.component';
import { ExecutiveenqviewComponent } from './executiveenqview/executiveenqview.component'; 
import { NotfollowedupComponent } from './notfollowedup/notfollowedup.component';
import { FollowupnotrequiredComponent } from './followupnotrequired/followupnotrequired.component';
import { ExportedtoreassignComponent } from './exportedtoreassign/exportedtoreassign.component';
import { InreviewComponent } from './inreview/inreview.component';

// const routes: Routes = [];

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "loading",
    component: LoadingComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent
    , canActivate: [AuthGuard],

  },
  {
    path: "about",
    component: AboutComponent
    , canActivate: [AuthGuard]
  },
  {
    path: "importantenqs",
    component: ImportantenqsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "newenquiries",
    component: NewenquiriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "inreview",
    component: InreviewComponent,
    canActivate: [AuthGuard]

  },
  {
    path: "inprogress",
    component: InprogressComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "submitted",
    component: SubmittedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "futurefollowups",
    component: FuturefollowupsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "notfollowedup",
    component: NotfollowedupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "clientcallbac",
    component: ClientcallbackComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "carnotavailable",
    component: CarnotavailableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "bought",
    component: BoughtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "lost",
    component: LostComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "lostforotherreasons",
    component: LostforotherreasonsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "executives",
    component: ExecutivesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "executiveenqview",
    component: ExecutiveenqviewComponent,
    canActivate: [AuthGuard]

  },
  {
    path: "enquiries",
    component: EnquiriesComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: "buyerenquiries",
    component:BuyerenquiriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "sellerenquiries",
    component: SellerenquiriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "addeditexecutive",
    component: AddeditexecutiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"addeditenquiry",
    component: AddeditenquiryComponent,
    canActivate:[AuthGuard]
  },
  {
    path:"enqresponses",
    component: EnqresponsesComponent,
    canActivate:[AuthGuard]
  },
  {
    path:"followupnotrequired",
    component: FollowupnotrequiredComponent,
    canActivate:[AuthGuard]
  },
  {
    path:"exportedtoreassign",
    component: ExportedtoreassignComponent,
    canActivate:[AuthGuard]
  },
  // {EnqresponsesComponent
  //   path: "customers",
  //   loadChildren: () =>
  //   import('./customer/customer.module').then(m => m.CustomerModule)
  //   , canActivate: [AuthGuard]
  // },
  // // {
  // //   path: "orders",
  // //   loadChildren: () =>
  // //   import('./order/order.module').then(m => m.OrderModule)
  // //   , canActivate: [AuthGuard]
  // // },
  // {
  //   path: "products",
  //   loadChildren: () =>
  //   import('./product/product.module').then(m => m.ProductModule)
  //   , canActivate: [AuthGuard]
  // },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: "**",
    component: NotFoundPageComponent
  },
  // ]
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

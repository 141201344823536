import { Component, Inject, OnInit } from '@angular/core';
import { buyerenquiries } from '../_models/buyerenquiries'
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmDialog } from '../shared';
import { SelectionModel } from '@angular/cdk/collections';
import { AssignmodalComponent } from '../assignmodal/assignmodal.component';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { CommentmodalComponent } from '../commentmodal/commentmodal.component';
import { EnqresponsesComponent } from '../enqresponses/enqresponses.component';

export interface PeriodicElement {
  name: string;
  
}
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-carnotavailable',
  templateUrl: './carnotavailable.component.html',
  styleUrls: ['./carnotavailable.component.scss'],
  providers: [ConfirmDialog]
})
export class CarnotavailableComponent implements OnInit {

  buyerenqs: buyerenquiries[];
  displayedColumns = ["company_id","name","phone","car","variant","year","last_updated","last_updated_by_user","enquiryID","select"];
  dataSource: any = null;
  selectedOption: string;
  selection;
  searchString: string;
  constructor(private route:ActivatedRoute, private authenticationService: AuthenticationService,public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.searchString = params.searchString;
      //alert(params.searchString)
      }
    )
    this.loadData()
  }

  loadData(){
    this.dataSource = this.authenticationService.getEnqListbystatus("car not available").then(data=>{
      
      this.dataSource = data["posts"]["data"];
      //alert(JSON.stringify(this.dataSource));
      this.freshDataList(this.dataSource);

      if(this.searchString != 'undefined')
      {
        this.applyFilter(this.searchString)
      }

      //this.applyFilter("x1");

      })
  }

  applyFilter(filterValue: string) {
    this.searchString = filterValue;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}

  freshDataList(buyenqs: any[]) {
    this.buyerenqs = buyenqs;

    this.dataSource = new MatTableDataSource<PeriodicElement>(this.buyerenqs);
    const data:  PeriodicElement[] = this.dataSource;
    this.selection = new SelectionModel<PeriodicElement>(true,[]);
    console.log(this.dataSource);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  NavigateToComments(row:any)
  {
    
    this.router.navigate(['../enqresponses',{'enquiryID':row.enquiryID,'name':row.name,'car1':row.car1,
    'varient1':row.variant1,'car2':row.car2,'varient2':row.varient2,'car3':row.car3,'searchString':this.searchString,'referPage':'carnotavailable'}]);
  }

  openDialog(id: number) {
    let dialogRef = this.dialog.open(ConfirmDialog,
        { data: { title: 'Dialog', message: 'Are you sure to delete this item?' } });
    dialogRef.disableClose = true;


   dialogRef.afterClosed().subscribe(result => {
        this.selectedOption = result;

        if (this.selectedOption === dialogRef.componentInstance.ACTION_CONFIRM) {
          this.authenticationService.deleteEnq(id,"","")
          .then(data => {
            let a = data["posts"]
            if(a[0].status == "success")
            {
              alert("Deleted enquiry successfully!")
              //window.location.reload();
              this.loadData();
            }
          });            
        }
      });
    }

    //openAssignDialog(){}
    // : void {
    //   const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
    //     width: '250px',
    //     //data: {name: this.name, animal: this.animal},
    //   });
  
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log('The dialog was closed');
    //     //this.animal = result;
    //   });
    // }
    openAssignDialog(): void {
      var selectedIds = ""
      this.selection.selected.forEach(s => selectedIds = selectedIds + s.enquiryID + ",");
      const dialogRef = this.dialog.open(AssignmodalComponent, {
        width: '350px',
        data: { selectedIds }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        //this.city = result;
        //this.food_from_modal = result.food;
      });
    }

    openCommentDialog(id: any) {
      //alert(id);
      const dialogRef = this.dialog.open(CommentmodalComponent, {
        width: '700px',
        maxHeight: '500px',
        data: { id }
      });
  
  
     dialogRef.afterClosed().subscribe(result => {
          
        });
      }
  }

  

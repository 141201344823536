import { Component, OnInit } from '@angular/core';
import { AfterViewInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';


import { NumberValidators } from '../shared/number.validator';
import { GenericValidator } from '../shared/generic-validator';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Executive } from '../_models/executive';

@Component({
  selector: 'app-addeditexecutive',
  templateUrl: './addeditexecutive.component.html',
  styleUrls: ['./addeditexecutive.component.scss']
})
export class AddeditexecutiveComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    pageTitle: string = 'Update Customer';
    errorMessage: string;
    executiveForm: FormGroup;
    executive: Executive = <Executive>{};
    private sub: Subscription;
    showImage: boolean;
    imageWidth: number = 100;
    imageMargin: number = 2;
    fieldColspan = 3;

    displayMessage: { [key: string]: string } = {};
    private genericValidator: GenericValidator;


    private validationMessages: { [key: string]: { [key: string]: string } | {} } = {
      name: {
          required: 'Executive name is required.'
      },
      username: {
          required: 'Executive username is required.'
      },
      password: {
          required: 'Executive password is required.'
      },      
      phone: { maxlength: 'Executive phone cannot exceed 12 characters.' },
    };

    constructor(private fb : FormBuilder,private breakpointObserver: BreakpointObserver,
        private authenticationService: AuthenticationService) { 
          breakpointObserver.observe([
            Breakpoints.HandsetLandscape,
            Breakpoints.HandsetPortrait
        ]).subscribe(result => {
            // console.log(result)
            this.onScreensizeChange(result);
        });
        this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit(): void {
    this.executiveForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      phone: ['', [Validators.required, Validators.maxLength(12)]],
      username: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
      password: ['', Validators.required],
      location: ['', Validators.maxLength(12)]
  });
  }

  saveExecutive(): void {
    if (this.executiveForm.dirty && this.executiveForm.valid) {
        const exec = Object.assign({}, this.executive, this.executiveForm.value);
        //alert (JSON.stringify(exec));
        this.authenticationService.saveExecutive(exec).then(data=>{
          alert(JSON.stringify(data));      
    ``  });
    }
  }
  
  onScreensizeChange(result: any) {
    // debugger
    const isLess600 = this.breakpointObserver.isMatched('(max-width: 599px)');
    const isLess1000 = this.breakpointObserver.isMatched('(max-width: 959px)');
    console.log(
        ` isLess600  ${isLess600} 
        isLess1000 ${isLess1000}  `
    )
    if (isLess1000) {
        if (isLess600) {
            this.fieldColspan = 12;
        }
        else {
            this.fieldColspan = 6;
        }
    }
    else {
        this.fieldColspan = 3;
    }
}

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { buyerenquiries } from '../_models/buyerenquiries'
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialog } from '../shared';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';


@Component({
  selector: 'app-submitted',
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss'],
  providers: [ConfirmDialog]
})
export class SubmittedComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buyerenqs: buyerenquiries[];
  //displayedColumns = ["company_id","name","phone","car","Source","DateCreated","last_updated","last_updated_by_user","enqAge","updatedount","status","enquiryID"];
  displayedColumns = ["company_id","name","phone","car","variant","year","DateCreated","last_updated","last_updated_by_user","status","enquiryID"];
  dataSource: any = null;
  selectedOption: string;
  constructor(private authenticationService: AuthenticationService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
    
  }

  loadData(){
    //alert("start");
    this.dataSource = this.authenticationService.getbuyerenquiriessubmitted().then(data=>{
      //alert("end")
      this.dataSource = data["posts"]["data"];
      console.log(this.dataSource);
      this.freshDataList(this.dataSource);

      })
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}
  freshDataList(buyenqs: any[]) {
    this.buyerenqs = buyenqs;

    this.buyerenqs.forEach((element,index) => {
      if(element.enq_status==="export" || element.enq_status==="Export for reassignment"){
        this.buyerenqs[index].enq_status = "Abort"
      }
    });

    this.dataSource = new MatTableDataSource(this.buyerenqs);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
  }

  NavigateToComments(row:any)
  {
    
    this.router.navigate(['../enqresponses',{'enquiryID':row.enquiryID,'name':row.name,'car1':row.car1,
    'varient1':row.variant1,'car2':row.car2,'varient2':row.varient2,'car3':row.car3}]);
  }

  test()
  {
    return false;
  }


  openDialog(id: number) {
    let dialogRef = this.dialog.open(ConfirmDialog,
        { data: { title: 'Dialog', message: 'Are you sure to delete this item?' } });
    dialogRef.disableClose = true;


   dialogRef.afterClosed().subscribe(result => {
        this.selectedOption = result;

        if (this.selectedOption === dialogRef.componentInstance.ACTION_CONFIRM) {
          this.authenticationService.deleteEnq(id,"","")
          .then(data => {
            let a = data["posts"]
            if(a[0].status == "success")
            {
              alert("Deleted enquiry successfully!")
              //window.location.reload();
              this.loadData();
            }
          });            
        }
      });
    }

}

<mat-card class='light' style="height: 100%;">
    <mat-grid-list cols="4" rowHeight="250px">
        <mat-grid-tile *ngFor="let card of exeengdtls">
            <mat-card class="my-card" style="background-color: #98caa7;">
                <mat-card-title>{{card.name}}</mat-card-title>
                <mat-card-content>
                   <div style="cursor: pointer"><a [routerLink]="['../inprogress']" style="cursor: pointer"> Inprogress: {{card.OnGoing}}</a></div>
                   <div><a [routerLink]="['../submitted']" style="cursor: pointer">Submitted: {{card.Submitted}}</a></div>
                   <div><a [routerLink]="['../futurefollowups']" style="cursor: pointer">Future Follow Up: {{card.FutureFollowUp}}</a></div>
                   <div><a [routerLink]="['../carnotavailable']" style="cursor: pointer">Car not available: {{card.Carnotavailable}}</a></div>
                   <div><a [routerLink]="['../clientcallbac']" style="cursor: pointer">Client will get back: {{card.Clientwillgetback}}</a></div>

                </mat-card-content>
              </mat-card> 
        </mat-grid-tile>
    </mat-grid-list>
</mat-card>


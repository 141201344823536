<!-- <mat-sidenav-container> -->

<mat-grid-list [cols]="colNum" [rowHeight]="rowHeight">
  <mat-grid-tile>
    <mat-card style="background-color: #98caa7;" class="info-dash-card" routerLink="../importantenqs">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">notification_important</mat-icon>
        </div>
        <mat-card-title>Important</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{important}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>    
  </mat-grid-tile>
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #cf9ecb;" class="info-dash-card" routerLink="../newenquiries">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">directions_walk</mat-icon>
        </div>
        <mat-card-title>Today's walkin</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{newentry}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card style="background-color: #c1abbf;" class="info-dash-card" routerLink="../inreview">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">rate_review</mat-icon>
        </div>
        <mat-card-title>In review</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{inreview}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #e6cea3;" class="info-dash-card" routerLink="../inprogress">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">assignment_ind</mat-icon>
        </div>
        <mat-card-title>Inprogress</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{inprogress}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../submitted">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">assignment_turned_in</mat-icon>
        </div>
        <mat-card-title>Submitted</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{submitted}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../futurefollowups">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">event_note</mat-icon>
        </div>
        <mat-card-title>Future followups</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{futureFollowUp}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../clientcallbac">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">phone_callback</mat-icon>
        </div>
        <mat-card-title>Client will get back</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{clientCallBack}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../carnotavailable">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">directions_car</mat-icon>
        </div>
        <mat-card-title>Car not available</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{cna}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../bought">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">thumb_up_alt</mat-icon>
        </div>
        <mat-card-title>Bought</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{bought}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../lost">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">thumb_down_alt</mat-icon>
        </div>
        <mat-card-title>Lost</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{lost}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
  <!-- <mat-grid-tile>
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../lostforotherreasons">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">thumb_down_alt</mat-icon>
        </div>
        <mat-card-title>Lost For Other reasons</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{lostforotherreasons}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile> -->
  <!-- <mat-grid-tile>
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../notfollowedup">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">warning</mat-icon>
        </div>
        <mat-card-title>Not Followedup</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{notFollowedUp}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile> -->
  <!-- <mat-grid-tile>
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../followupnotrequired">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">stop</mat-icon>
        </div>
        <mat-card-title>Follow up not required</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>0</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile> -->
  <mat-grid-tile *ngIf="isSupuerAdmin">
    <mat-card style="background-color: #adcae2;" class="info-dash-card" routerLink="../exportedtoreassign">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">stop</mat-icon>
        </div>
        <mat-card-title>Abort</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{reassign}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>



  <!-- <mat-grid-tile  *ngFor="let infoBox of infoBoxes">
    <mat-card [ngClass]="infoBox.bgClass" class="info-dash-card" routerLink="../{{infoBox.routing}}">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon matListIcon class="icon">{{infoBox.icon}}</mat-icon>
        </div>
        <mat-card-title>{{infoBox.title}}</mat-card-title>
        <mat-card-subtitle class="subtitle">
          <strong>{{infoBox.subtitle}}</strong>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile> -->
</mat-grid-list>


<!-- <mat-grid-list [cols]="chartColNum" rowHeight="1.8:1"  style="margin-top:1rem;"> -->
  <!-- style="height: 100%" [rowHeight]="chartRowHeight"-->
  <!-- <mat-grid-tile > -->
    <!-- <mat-card class="dash-card" style="width:95%"> -->
    <!-- <mat-card class="dash-card" >
  <mat-card-content class="dash-card-content">
      <canvas baseChart [data]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" chartType="line"
        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
      </mat-card-content>
      </mat-card>
  </mat-grid-tile> -->
  <!-- style="height: 100%" -->
  <!-- <mat-grid-tile > -->
    <!-- <mat-card class="dash-card" style="width:95%"> -->
    <!-- <mat-card class="dash-card" >
      <mat-card-content class="dash-card-content">
      <canvas baseChart [data]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" chartType="bar"
        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
      </mat-card-content>
    </mat-card>

  </mat-grid-tile>


</mat-grid-list> -->
<!-- </mat-sidenav-container> -->

import { Component, OnInit,ViewChild } from '@angular/core';
import { buyerenquiries } from '../_models/buyerenquiries'
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-buyerenquiries',
  templateUrl: './buyerenquiries.component.html',
  styleUrls: ['./buyerenquiries.component.scss']
})
export class BuyerenquiriesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buyerenqs: buyerenquiries[];
  displayedColumns = ["company_id","name","phone","car","variant","year","DateCreated","last_updated","last_updated_by_user","status","enquiryID"];
  dataSource: any = null;
  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    this.dataSource = this.authenticationService.getbuyerenquiriesall().then(data=>{
      
      this.dataSource = data["posts"]["data"];
      console.log(this.dataSource);
      this.freshDataList(this.dataSource);

      })
  }
  NavigateToComments(row:any)
  {
    
    this.router.navigate(['../enqresponses',{'enquiryID':row.enquiryID,'name':row.name,'car1':row.car1,
    'varient1':row.variant1,'car2':row.car2,'varient2':row.varient2,'car3':row.car3}]);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}
  freshDataList(buyenqs: any[]) {
    this.buyerenqs = buyenqs;

    this.buyerenqs.forEach((element,index) => {
      if(element.enq_status==="export" || element.enq_status==="Export for reassignment"){
        this.buyerenqs[index].enq_status = "Abort"
      }
    });


    this.dataSource = new MatTableDataSource(this.buyerenqs);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
  }

}

<form novalidate (ngSubmit)="saveExecutive()" [formGroup]="executiveForm">
    <mat-card>
  
      <mat-toolbar style="background-color: #FFF">
        <mat-toolbar-row>
          <span class="view-card-title"> Executive</span>
          <span class="title-spacer"></span>
          <mat-card-actions>
            <button mat-mini-fab class="primary" style="margin-right:10px; background-color:darkgray" type="submit"
              [disabled]='!executiveForm.valid'>
              <mat-icon>save</mat-icon>
            </button>
            <button mat-mini-fab class="" style="margin-right:10px; " routerLink='../executives'>
              <mat-icon>cancel</mat-icon>
            </button>
  
          </mat-card-actions>
  
        </mat-toolbar-row>
      </mat-toolbar>
  
      <mat-card-content style="margin: 5 0 5 10px;">
        <div  class="form-card">
          <mat-grid-list cols="12" rowHeight="80px">
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput id="nameId" type="text" placeholder="Name (required)"
                formControlName="name" />
                <mat-error *ngIf="displayMessage.name">
                  {{displayMessage.name}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput id="phoneId" type="text" placeholder="phone (required)"
                  formControlName="phone" />
                <mat-error *ngIf="displayMessage.phone">
                  {{displayMessage.phone}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput id="usernameId" type="text" placeholder="username (required)"
                  formControlName="username" />
                <mat-error *ngIf="displayMessage.username">
                  {{displayMessage.username}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput id="passwordId" type="text" placeholder="password (required)"
                  formControlName="password" />
                <mat-error *ngIf="displayMessage.password">
                  {{displayMessage.password}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Location</mat-label>
                <input matInput id="locationId" type="text" placeholder="Location (required)"
                  formControlName="location" />
                <mat-error *ngIf="displayMessage.location">
                  {{displayMessage.location}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>          
        </div>
      </mat-card-content>  
    </mat-card>
  </form>
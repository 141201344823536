<mat-card class='light' style="height: 100%;">
    <mat-card-header>
        <mat-card-title class="view-card-title">
          <h1>In review</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-toolbar class="primary">
      <mat-toolbar-row>
        <button mat-mini-fab style="margin-right:10px; background-color:#a28b6e" >
          <mat-icon>search</mat-icon>
        </button>
        <button mat-mini-fab style="margin-right:10px; background-color:darkgray" >
          <mat-icon>refresh</mat-icon>
        </button>
  
        <span class="title-spacer"></span>
        <mat-form-field appearance="standard" class="search-form-fileld">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event.target.value)">
          <mat-icon>filter_list</mat-icon>
        </mat-form-field>
  
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-table #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="company_id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
        <mat-cell *matCellDef="let buyerenq"> {{buyerenq.enquiryID}} </mat-cell>
      </ng-container>
        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef mat-sort-header> phone </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.phone}} </mat-cell>
        </ng-container>     
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> name </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="car">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Car </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.car1}} <br> {{buyerenq.car2}} <br> {{buyerenq.car3}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="variant">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Variant </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.variant1}} <br> {{buyerenq.variant2}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="year">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Year </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.year}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="DateCreated">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Created on </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.created_on}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="last_updated">
          <mat-header-cell *matHeaderCellDef mat-sort-header> last updated Date </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.enq_last_updated}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="last_updated_by_user">
          <mat-header-cell *matHeaderCellDef mat-sort-header> last updated By </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq"> {{buyerenq.enq_last_updated_user}} </mat-cell>
        </ng-container>   
        <!-- <ng-container matColumnDef="enqAge">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry Age </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq">{{buyerenq.age}}  </mat-cell>
        </ng-container> 
        <ng-container matColumnDef="updatedount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> No. of Updates </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq">{{buyerenq.followups}}  </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq">{{buyerenq.enq_status}}  </mat-cell>
        </ng-container>
        <ng-container matColumnDef="enquiryID">
          <mat-header-cell *matHeaderCellDef style="justify-content:right;">
            <!-- <button mat-button style="color:white; background-color:darkgrey" (click)="resetListFilter()">Reset</button> -->
          </mat-header-cell>
          <mat-cell *matCellDef="let buyerenq" style="justify-content:right;"  (click)="$event.stopPropagation()">
            <button mat-mini-fab style="background-color:teal; min-height: 30px;   margin: 5px;"
            [routerLink]="['../addeditenquiry',{'enquiryID':buyerenq.enquiryID}]">
              <mat-icon>edit</mat-icon>
            </button>
            <!-- <button mat-mini-fab style="background-color:lightblue; min-height: 30px;  margin: 5px;"
              [routerLink]="['../enqresponses',{'enquiryID':buyerenq.enquiryID,'name':buyerenq.name,'car1':buyerenq.car1,
              'varient1':buyerenq.variant1,'car2':buyerenq.car2,'varient2':buyerenq.varient2,'car3':buyerenq.car3}]">
              <mat-icon>note</mat-icon>
            </button> -->
            <button mat-mini-fab style="background-color:lightblue; min-height: 30px;  margin: 5px;"
              (click)="openDialog(buyerenq.enquiryID)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row (click)="openCommentDialog(row.enquiryID)" *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10]">
      </mat-paginator>    
</mat-card>
import { Component, OnInit } from '@angular/core';
import { buyerenquiries } from '../_models/buyerenquiries'
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialog } from '../shared';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AssignmodalComponent } from '../assignmodal/assignmodal.component';
import { CommentmodalComponent } from '../commentmodal/commentmodal.component';

export interface PeriodicElement {
  name: string;
  
}
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-lost',
  templateUrl: './lost.component.html',
  styleUrls: ['./lost.component.scss'],
  providers: [ConfirmDialog]
})
export class LostComponent implements OnInit {

  buyerenqs: buyerenquiries[];
  displayedColumns = ["ID","name","phone","car","variant","year","DateCreated","last_updated","last_updated_by_user","enquiryID","select"];
  dataSource: any = null;
  selectedOption: string;
  selection;
  constructor(private authenticationService: AuthenticationService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    this.dataSource = this.authenticationService.getEnqListbystatus("Lost").then(data=>{
      
      this.dataSource = data["posts"]["data"];
      this.freshDataList(this.dataSource);

      })

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}

  freshDataList(buyenqs: any[]) {
    this.buyerenqs = buyenqs;

    this.dataSource = new MatTableDataSource(this.buyerenqs);
    this.selection = new SelectionModel<PeriodicElement>(true,[]);
    console.log(this.dataSource);
  }

  NavigateToComments(row:any)
  {
    
    this.router.navigate(['../enqresponses',{'enquiryID':row.enquiryID,'name':row.name,'car1':row.car1,
    'varient1':row.variant1,'car2':row.car2,'varient2':row.varient2,'car3':row.car3}]);
  }

  openDialog(id: number) {
    let dialogRef = this.dialog.open(ConfirmDialog,
        { data: { title: 'Dialog', message: 'Are you sure to delete this item?' } });
    dialogRef.disableClose = true;


   dialogRef.afterClosed().subscribe(result => {
        this.selectedOption = result;

        if (this.selectedOption === dialogRef.componentInstance.ACTION_CONFIRM) {
          this.authenticationService.deleteEnq(id,"","")
          .then(data => {
            let a = data["posts"]
            if(a[0].status == "success")
            {
              alert("Deleted enquiry successfully!")
              //window.location.reload();
              this.loadData();
            }
          });            
        }
      });
    }

    openAssignDialog(): void {
      var selectedIds = ""
      this.selection.selected.forEach(s => selectedIds = selectedIds + s.enquiryID + ",");
      const dialogRef = this.dialog.open(AssignmodalComponent, {
        width: '350px',
        data: { selectedIds }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        //this.city = result;
        //this.food_from_modal = result.food;
      });
    }

    openCommentDialog(id: any) {
      //alert(id);
      const dialogRef = this.dialog.open(CommentmodalComponent, {
        width: '700px',
        maxHeight: '500px',
        data: { id }
      });

      dialogRef.afterClosed().subscribe(result => {
          
      });
    }

}

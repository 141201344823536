import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-assignmodal',
  templateUrl: './assignmodal.component.html',
  styleUrls: ['./assignmodal.component.scss']
})
export class AssignmodalComponent implements OnInit {
  comment: string;
  selectedExecutive : string;
  executives = [];
  constructor(public dialogRef: MatDialogRef<AssignmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    console.log(JSON.stringify(this.data));
    console.log(this.data.selectedIds)
    this.LoadExecutiveList();
  }
  LoadExecutiveList(){

    this.authenticationService.getAllExecutive().then(data=>{
      //debugger;
      this.executives = data["posts"];
      //alert(JSON.stringify(this.executives));
      
      });
  }

  onNoClick(): void {
    
    this.dialogRef.close({
      //food: this.food
    });
  }

  onAssignClick(): void{
    console.log(this.comment);
    console.log(this.selectedExecutive);
    var ids = this.data.selectedIds;
    var lastChar = ids.charAt(ids.length-1);
    if(lastChar == ",")
    {
      ids = ids.substring(0,ids.length - 1)
    }
    console.log(ids);
     this.authenticationService.multiAssign(ids,this.comment,this.selectedExecutive).then(data => {
      let a = data["posts"]
      //alert(JSON.stringify(a[0]))
      if(a[0].status == "success")
      {
        alert("Assigned successfully!")
        window.location.reload();
      }
      //  this.dialogRef.close({
      //   //food: this.food
      // });
      //window.location.reload();

     })


  }


}

<form novalidate (ngSubmit)="saveBuyerEnq()" [formGroup]="buyerenqForm">
    <mat-card>
  
      <mat-toolbar style="background-color: #FFF">
        <mat-toolbar-row>
          <span class="view-card-title"> Buyer enq</span>
          <span class="title-spacer"></span>
          <mat-card-actions>
            <button mat-mini-fab class="primary" style="margin-right:10px; background-color:darkgray" type="submit"
              [disabled]='!buyerenqForm.valid'>
              <mat-icon>save</mat-icon>
            </button>
            <button mat-mini-fab class="" style="margin-right:10px; " (click)="goback()">
              <mat-icon>cancel</mat-icon>
            </button>
  
          </mat-card-actions>
  
        </mat-toolbar-row>
      </mat-toolbar>
  
      <mat-card-content style="margin: 5 0 5 10px;">
        <div  class="form-card">
          <mat-grid-list cols="12" rowHeight="80px">
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput id="nameId" type="text" placeholder="Name (required)"
                formControlName="name" />
                <mat-error *ngIf="displayMessage.name">
                  {{displayMessage.name}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput id="phoneId" type="text" placeholder="phone (required)"
                  formControlName="phone" />
                <mat-error *ngIf="displayMessage.phone">
                  {{displayMessage.phone}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Car1</mat-label>
                <mat-select  (selectionChange)="carmakechange1($event.value)" formControlName="car1">
                  <mat-option *ngFor="let cars of carmakerList" [value]="cars.name">
                    {{cars.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Varient 1</mat-label>
                  <mat-select formControlName="variant1">
                    <mat-option *ngFor="let varient of carvarient1" [value]="varient.name">
                      {{varient.name}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Car 2</mat-label>
                <mat-select (selectionChange)="carmakechange2($event.value)" formControlName="car2">
                  <mat-option *ngFor="let cars of carmakerList" [value]="cars.name">
                    {{cars.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Varient 2</mat-label>
                <mat-select formControlName="variant2">
                  <mat-option *ngFor="let varient of carvarient2" [value]="varient.name">
                    {{varient.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Car 3</mat-label>
                <input matInput id="car3Id" type="text" formControlName="car3" />
                <mat-error *ngIf="displayMessage.password">
                  {{displayMessage.car3}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Year Above</mat-label>
                <input matInput id="yearId" type="text" formControlName="year" />
                <mat-error *ngIf="displayMessage.password">
                  {{displayMessage.car3}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <!-- <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Color not prefered</mat-label>
                <input matInput id="colornpId" type="text" placeholder="password (required)"
                  formControlName="colornp" />
                <mat-error *ngIf="displayMessage.password">
                  {{displayMessage.colornp}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Number not prefered</mat-label>
                <input matInput id="numbernpId" type="text" placeholder="password (required)"
                  formControlName="numbernp" />
                <mat-error *ngIf="displayMessage.password">
                  {{displayMessage.password}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile> -->
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>source</mat-label>
                <input matInput id="sourceId" type="text" placeholder="source"
                formControlName="source" />                
              </mat-form-field>
            </mat-grid-tile>
            <!-- <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Deciding Time</mat-label>
                <mat-select formControlName="decidingTime" (selectionChange)="changeDT($event.value)">
                  <mat-option  value="ASAP">
                    ASAP
                  </mat-option>
                  <mat-option  value="DATE">
                    Date
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile> -->
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Deciding Date</mat-label>
                <input matInput [matDatepicker]="picker"
                [formControl]="deciding_time1" [disabled]="isNewEntry"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>                
              </mat-form-field>
            </mat-grid-tile>
            <!-- <mat-grid-tile [colspan]="fieldColspan">
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button value="1">Change Status</mat-radio-button>
                <mat-radio-button value="2">Re-assign</mat-radio-button>
              </mat-radio-group>
            </mat-grid-tile> -->
            <mat-grid-tile [colspan]="fieldColspan" >
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select formControlName="enq_status" [disabled]="isNewEntry">
                  <mat-option  value="Submitted">Submitted
                  </mat-option>
                  <mat-option  value="Inprogress">Inprogress
                  </mat-option>
                  <mat-option  value="Lost">Lost
                  </mat-option>
                  <mat-option  value="Important">
                    Important
                  </mat-option>
                  <mat-option  value="Car not available">
                    Car not available
                  </mat-option>
                  <mat-option  value="export">
                    Abort
                  </mat-option>
                  <!-- <mat-option  value="Submitted">
                    Submitted
                  </mat-option> -->
                  <!-- <mat-option  value="Lostfor">
                    Lost for other reasons
                  </mat-option> -->
                  <mat-option  value="Bought">
                    Bought
                  </mat-option>
                  <mat-option  value="Client will get back">
                    Client will get back
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>            
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Agent</mat-label>
                <mat-select formControlName="agent_assigned_name">
                  <mat-option *ngFor="let executive of executives" [value]="executive.name">
                    {{executive.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="fieldColspan">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Comment</mat-label>
                <textarea matInput id="commentId"  placeholder="Comment (required)" formControlName="comments"></textarea>
                <mat-error *ngIf="displayMessage.name">
                  {{displayMessage.name}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            
          </mat-grid-list>          
        </div>
      </mat-card-content>  
    </mat-card>
  </form>
import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '../_services';
import {ActivatedRoute} from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Location } from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialog } from '../shared';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-commentmodal',
  templateUrl: './commentmodal.component.html',
  styleUrls: ['./commentmodal.component.scss']
})
export class CommentmodalComponent implements OnInit {

  dataSource: any = null;
  custName: string;
  enqId: string
  cars: string;
  car1: string = "";
  varient1: string = "";
  car2: string = "";
  varient2: string ="";
  car3: string ="";
  comments: string="";
  refferingPage: string="";
  displayedColumns = ["date","comment","created_by","comment_id"];
  selectedOption: string;
  searchString: string = "";
  isSuperAdmin: boolean;
  isReviewer: boolean;
  username: string;
  constructor(public dialogRef: MatDialogRef<CommentmodalComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private route:ActivatedRoute, private authenticationService: AuthenticationService,
     private location: Location, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.username = localStorage.getItem("username");
    if(this.username == "super_admin"){
      this.isSuperAdmin = true;
    }
    else{
      this.isReviewer = true;
    }
    //alert(JSON.stringify(this.data))
    this.route.params.subscribe(params => {
      //alert(params.enquiryID);
      //alert(JSON.stringify(this.data));
      console.log(params);
      this.custName = params.name;
      this.enqId = this.data.id;
      //alert(this.enqId);
      if(params.car1 != 'undefined' && params.car1 != null && params.car1 !="")
      {
        this.car1 = params.car1
      }
      if(params.car2 != 'undefined' && params.car2 != null && params.car2 !="")
      {
        this.car2 = params.car2
      }
      if(params.car3 != 'undefined' && params.car3 != null && params.car3 !="")
      {
        this.car3 = params.car3
      }
      if(params.varient1 != 'undefined' && params.varient1 != null && params.varient1 !="")
      {
        this.varient1 = params.varient1
      }
      if(params.varient2 != 'undefined' && params.varient2 != null && params.varient2 !="")
      {
        this.varient2 = params.varient2
      }
      if(params.referPage != 'undefined' && params.referPage != null && params.referPage !== "")
      {
        this.refferingPage = params.referPage
      }
      this.searchString = params.searchString;
      this.cars = this.car1 + ' '+ this.varient1 + ' ' + this.car2 + ' ' + this.varient2 + ' ' + this.car3;
      //car1 +' '+ params.varient1 + ' '+ params.car2 + ' ' + params.varient2 + ' ' + params.car3;
      //this.enquiryDetails = params;
    })
    // this.dataSource = this.authenticationService.getEnqResponses(this.enqId).then(data=>{
      
    //   this.dataSource = data["posts"];
    //   console.log(this.dataSource);
    //   //alert(JSON.stringify(this.dataSource));
    //   this.freshDataList(this.dataSource);

    //   })
    this.loadData();
  }

  loadData(){
    //alert(this.data.id)
    this.dataSource = this.authenticationService.getEnqResponses(this.data.id).then(data=>{
      
      this.dataSource = data["posts"];
      console.log(this.dataSource);
      //alert(JSON.stringify(this.dataSource));
      this.freshDataList(this.dataSource);

      })

  }

  freshDataList(enqResponses: any[]) {
    this.dataSource = enqResponses;

    this.dataSource = new MatTableDataSource(this.dataSource);
    console.log(this.dataSource);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  goback(){
    // history.pushState({page: 1}, "title 1", "?page=1");
    // window.location = document.referrer
    if(this.refferingPage != "")
    {
      
      this.router.navigate(['../'+this.refferingPage,{'searchString':this.searchString}])
    }
    else
    {
      this.location.back();
    }
  }

  AddComment(){
    if(this.comments==""){
      alert("Please enter comments");
    }
    else{
      var notes = encodeURIComponent(this.comments);
      this.authenticationService.updateEnqComment(this.enqId,notes,"","")
      .then(data => {
        let a = data["posts"]
        if(a.status == "success")
        {
          alert("Added comments successfully!")
          //window.location.reload();
          this.loadData();
        }
      });
      
    }
  }

  openDialog(id: number) {
     let dialogRef = this.dialog.open(ConfirmDialog,
         { data: { title: 'Dialog', message: 'Are you sure to delete this item?' } });
     dialogRef.disableClose = true;


    dialogRef.afterClosed().subscribe(result => {
        this.selectedOption = result;

        if (this.selectedOption === dialogRef.componentInstance.ACTION_CONFIRM) {
          this.authenticationService.deleteEnqComment(id,"","")
          .then(data => {
            let a = data["posts"]
            if(a[0].status == "success")
            {
              alert("Deleted comments successfully!")
              //window.location.reload();
              this.loadData();
            }
          });            
        }
    });
  }

  

}

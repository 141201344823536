<div style="height: 150px; overflow: hidden;"><svg viewBox="0 0 500 150" preserveAspectRatio="none"
    style="height: 100%; width: 100%;">
    <path d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
      style="stroke: none; fill: #08f;"></path>
  </svg></div>
<div  id="container" class="container">
  <mat-card class="login-card" >
    <form class="loginForm" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
      <!-- <div> -->
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <img src="../../assets/img/logo.png" height="80px">
      </mat-card-header>
      <mat-card-content class="field-item">
        <mat-form-field style="display: block" [ngClass]="{  'mat-input-error': f.submitted && !username.valid }">
          <input matInput type="text" placeholder="User Name Reviewer" name="username" [(ngModel)]="model.username"
            #username="ngModel" required />
        </mat-form-field>
      </mat-card-content>
      <mat-card-content class="field-item">
        <mat-form-field style="display: block"
          [ngClass]="{ 'display': 'block','mat-input-error': f.submitted && !password.valid }">
          <input matInput type="password" placeholder="Password" name="password" [(ngModel)]="model.password"
            #password="ngModel" required />
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions class="action-item">
        <button mat-raised-button [disabled]="isValidating">Login</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import { buyerenquiries } from '../_models/buyerenquiries'
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import { test } from '../_models/test';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialog } from '../shared';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';


@Component({
  selector: 'app-importantenqs',
  templateUrl: './importantenqs.component.html',
  styleUrls: ['./importantenqs.component.scss'],
  providers: [ConfirmDialog]
})
export class ImportantenqsComponent implements OnInit {

  buyerenqs: buyerenquiries[];
  enqs: test[];
  selectedOption: string;
  //displayedColumns = ["id","name","","createdon","updatedon","car1","cretedby","status","lastupdatedby"];
  displayedColumns = ["ID","name","phone","car","variant","year","DateCreated","last_updated","last_updated_by_user","enquiryID"];
  dataSource: any = null;
  constructor(private authenticationService: AuthenticationService, public dialog: MatDialog, private router: Router,) { }

  ngOnInit(): void {
    this.loadData()
    
  }

  loadData(){
    this.dataSource = this.authenticationService.getEnqListbystatus("important").then(data=>{
      //debugger;
      this.dataSource = data["posts"]["data"];
      //console.log(this.executives[0])
      //console.log(this.executives);
      //alert(JSON.stringify(this.taskItems));
      this.freshDataList(this.dataSource);
      })

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}

  freshDataList(buyenqs: any[]) {
    //alert("Inside Fresh");
    //alert(JSON.stringify(buyenqs))
    this.buyerenqs = buyenqs;
    this.enqs = buyenqs;
    //alert(JSON.stringify(this.enqs))

    this.dataSource = new MatTableDataSource(this.enqs);
    console.log(this.dataSource);
    //alert("come on "+JSON.stringify(this.dataSource));

    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }
  NavigateToComments(row:any)
  {
    //alert(JSON.stringify(row));
    //alert(row.enquiryID);
    this.router.navigate(['../enqresponses',{'enquiryID':row.enquiryID,'name':row.name,'car1':row.car1,
    'varient1':row.variant1,'car2':row.car2,'varient2':row.varient2,'car3':row.car3}]);
  }
  openDialog(id: number) {
    let dialogRef = this.dialog.open(ConfirmDialog,
        { data: { title: 'Dialog', message: 'Are you sure to delete this item?' } });
    dialogRef.disableClose = true;


   dialogRef.afterClosed().subscribe(result => {
        this.selectedOption = result;

        if (this.selectedOption === dialogRef.componentInstance.ACTION_CONFIRM) {
          this.authenticationService.deleteEnq(id,"","")
          .then(data => {
            let a = data["posts"]
            if(a[0].status == "success")
            {
              alert("Deleted enquiry successfully!")
              //window.location.reload();
              this.loadData();
            }
          });            
        }
      });
    }

}

<div mat-dialog-content>
    <p>Select</p>
    <mat-form-field>
        <mat-form-field>
            <mat-label>Agent</mat-label>
            <mat-select [(ngModel)]="selectedExecutive">
              <mat-option *ngFor="let executive of executives" [value]="executive.name">
                {{executive.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
    </mat-form-field> 
    <mat-form-field >
      <mat-label>Comment</mat-label>
      <textarea matInput id="commentId"  placeholder="Comment (required)" [(ngModel)]="comment"></textarea>      
    </mat-form-field>   
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onAssignClick()">Assign</button>
</div>

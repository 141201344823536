import { Component, OnInit } from '@angular/core';
import { buyerenquiries } from '../_models/buyerenquiries'
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lostforotherreasons',
  templateUrl: './lostforotherreasons.component.html',
  styleUrls: ['./lostforotherreasons.component.scss']
})
export class LostforotherreasonsComponent implements OnInit {

  buyerenqs: buyerenquiries[];
  displayedColumns = ["company_id","name","phone","car","Source","DateCreated","last_updated","last_updated_by_user","enquiryID"];
  dataSource: any = null;
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.dataSource = this.authenticationService.getEnqListbystatus("Lost for other reasons").then(data=>{
      
      this.dataSource = data["posts"]["data"];
      this.freshDataList(this.dataSource);

      })

  }
  freshDataList(buyenqs: any[]) {
    this.buyerenqs = buyenqs;

    this.dataSource = new MatTableDataSource(this.buyerenqs);
    console.log(this.dataSource);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}


}

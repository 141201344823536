<mat-card class='light' style="height: 100%;">
    <mat-card-header>
        <mat-card-title class="view-card-title">
          <h1>Executives</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-toolbar class="primary">
        <mat-toolbar-row>
            <button mat-mini-fab style="margin-right:10px; background-color:#e07c9e" routerLink='../addeditexecutive'>
                <mat-icon>add</mat-icon>
            </button>
            <button mat-mini-fab style="margin-right:10px; background-color:#a28b6e">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-mini-fab style="margin-right:10px; background-color:darkgray">
            <mat-icon>refresh</mat-icon>
            </button>
            
        </mat-toolbar-row>
      </mat-toolbar>  
      <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let executive"> {{executive.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Location </mat-header-cell>
          <mat-cell *matCellDef="let executive"> {{executive.location}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef mat-sort-header> phone </mat-header-cell>
          <mat-cell *matCellDef="let executive"> {{executive.phone}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="username">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Username </mat-header-cell>
          <mat-cell *matCellDef="let executive"> {{executive.username}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef style="justify-content:right;">
            <!-- <button mat-button style="color:white; background-color:darkgrey" (click)="resetListFilter()">Reset</button> -->
          </mat-header-cell>
          <mat-cell *matCellDef="let executive" style="justify-content:right;">
            <button mat-mini-fab style="background-color:teal; min-height: 30px;   margin: 5px;"
              [routerLink]="['/executives/edit/', executive.id]">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab style="background-color:lightblue; min-height: 30px;  margin: 5px;"
              >
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10]">
      </mat-paginator>
</mat-card>

<mat-card class='light' style="height: 100%;">
    <mat-toolbar class="primary">
       <mat-toolbar-row>
        <span class="title-spacer"></span>
         <mat-form-field appearance="standard" class="search-form-fileld">
           <mat-label>Filter</mat-label>
             <input matInput (keyup)="applyFilter($event.target.value)"> 
           <mat-icon>filter_list</mat-icon>
         </mat-form-field>   
       </mat-toolbar-row>
     </mat-toolbar>
     <mat-table #table [dataSource]="dataSource" matSort>
       <ng-container matColumnDef="date">
         <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
         <mat-cell *matCellDef="let dataSource"> {{dataSource.date}} </mat-cell>
       </ng-container> 
       <ng-container matColumnDef="comment">
         <mat-header-cell *matHeaderCellDef mat-sort-header> Comment </mat-header-cell>
         <mat-cell *matCellDef="let dataSource"> {{dataSource.comment}} </mat-cell>
       </ng-container> 
       <ng-container matColumnDef="created_by">
         <mat-header-cell *matHeaderCellDef mat-sort-header> Updated By </mat-header-cell>
         <mat-cell *matCellDef="let dataSource"> {{dataSource.created_by}} </mat-cell>
       </ng-container> 
       <ng-container matColumnDef="comment_id">
         <mat-header-cell *matHeaderCellDef style="justify-content:right;">
           <!-- <button mat-button style="color:white; background-color:darkgrey" (click)="resetListFilter()">Reset</button> -->
         </mat-header-cell>
         <mat-cell *matCellDef="let dataSource" style="justify-content:right;">
           <button mat-mini-fab style="background-color:lightblue; min-height: 30px;  margin: 5px;"
             (click)="openDialog(dataSource.comment_id)">
             <mat-icon>delete</mat-icon>
           </button>
         </mat-cell>
       </ng-container>
       <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
       <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight':row.created_by=='Super Admin'}"></mat-row>
     </mat-table>  
     <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10]">
     </mat-paginator>     
 </mat-card>
 <mat-card>
   <div  class="form-card" aria-colspan="4">
     <mat-form-field class="form-field" appearance="outline">
       <mat-label>Comment</mat-label>
       <textarea matInput  [(ngModel)] = "comments" placeholder="Comment (required)"></textarea>
       
     </mat-form-field>
   </div>
 </mat-card>
 <mat-card>
    <mat-toolbar class="primary">
        <mat-toolbar-row>
          <button mat-mini-fab style="margin-right:10px; background-color:darkgray" (click)="RejectStatus()">
            <mat-icon>refresh</mat-icon>
          </button>
          <button mat-mini-fab class="primary" style="margin-right:10px; background-color:darkgray" (click)="ApproveStatus()">
            <mat-icon>check_circle</mat-icon>
          </button>
    
          
    
        </mat-toolbar-row>
      </mat-toolbar>
 </mat-card>
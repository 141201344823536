import { Component, OnInit } from '@angular/core';
import { buyerenquiries } from '../_models/buyerenquiries'
import { AuthenticationService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialog } from '../shared';

@Component({
  selector: 'app-notfollowedup',
  templateUrl: './notfollowedup.component.html',
  styleUrls: ['./notfollowedup.component.scss'],
  providers: [ConfirmDialog]
})
export class NotfollowedupComponent implements OnInit {

  buyerenqs: buyerenquiries[];
  displayedColumns = ["ID","name","phone","car","Source","DateCreated","last_updated","last_updated_by_user","enquiryID"];
  dataSource: any = null;
  selectedOption: string;
  constructor(private authenticationService: AuthenticationService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    this.dataSource = this.authenticationService.getNotFollowedUp().then(data=>{
      
      this.dataSource = data["posts"]["data"];
      this.freshDataList(this.dataSource);

      })
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}

  freshDataList(buyenqs: any[]) {
    this.buyerenqs = buyenqs;

    this.dataSource = new MatTableDataSource(this.buyerenqs);
    console.log(this.dataSource);
  }

  openDialog(id: number) {
    let dialogRef = this.dialog.open(ConfirmDialog,
        { data: { title: 'Dialog', message: 'Are you sure to delete this item?' } });
    dialogRef.disableClose = true;


   dialogRef.afterClosed().subscribe(result => {
        this.selectedOption = result;

        if (this.selectedOption === dialogRef.componentInstance.ACTION_CONFIRM) {
          this.authenticationService.deleteEnq(id,"","")
          .then(data => {
            let a = data["posts"]
            if(a[0].status == "success")
            {
              alert("Deleted enquiry successfully!")
              //window.location.reload();
              this.loadData();
            }
          });            
        }
      });
    }

}
